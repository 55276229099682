import { gql, useMutation } from '@apollo/client';
import { ASSIGN_TO_GROUP, REMOVE_FROM_GROUP } from '../groups/groupMutations';

const CREATE_USER = gql`
  mutation ($username: String!) {
    AddUser(username: $username)
  }
`;

const SET_CRITERIA = gql`
  mutation ($username: String!, $fieldname: WritableUserFields!, $value: String!) {
    AssignUserCriteria(username: $username, fieldname: $fieldname, value: $value) 
  }
`;

const ADD_USER_FORM = gql`
  mutation ($user: UserInput) {
  AddUserForm(user: $user)
  }
`;

const EDIT_USER_FORM = gql`
  mutation ($user: UserInput) {
  EditUserForm(user: $user)
}
`;

const ENABLE_USER = gql`
  mutation ($username: String!) {
    EnableUser(username: $username)
  }
`;

const DISABLE_USER = gql`
  mutation ($username: String!) {
    DisableUser(username: $username)
  }
`;

const REMOVE_USER = gql`
  mutation ($username: String!) {
    RemoveUser(username: $username)
  }
`;

const UNLOCK_USER = gql`
  mutation ($id: String!) {
    UnlockUserIDP(id: $id)
  }
`;

const IMPORT_BULK = gql`
  mutation ($csvContents: String!) {
    UploadBulkUserCsv(csvContents: $csvContents)
  }
`;

export function useUserMutations() {

  const [create] = useMutation(CREATE_USER,
    { refetchQueries: ['getUsers'] },
  );

  const [setCriteria] = useMutation(SET_CRITERIA,
    { refetchQueries: ['getUsers', 'contextGetUserByID', 'contextGetDevices'] },
  );

  const [addUserForm] = useMutation(ADD_USER_FORM,
    { refetchQueries: ['getUsers','contextGetUserByID', 'contextGetDevices'] },
  );

  const [editUserForm] = useMutation(EDIT_USER_FORM,
    { refetchQueries: ['getUsers', 'contextGetUserByID', 'contextGetDevices'] },
  );

  const [enable] = useMutation(ENABLE_USER,
    { refetchQueries: ['getUsers'] },
  );

  const [disable] = useMutation(DISABLE_USER,
    { refetchQueries: ['getUsers'] },
  );

  const [remove] = useMutation(REMOVE_USER,
    { refetchQueries: ['getUsers'] },
  );

  const [unlock] = useMutation(UNLOCK_USER,
    { refetchQueries: ['getUsers'] },
  );

  // borrow group mutations here for correct refetch
  const [groupAssign] = useMutation(ASSIGN_TO_GROUP,
    { refetchQueries: ['getUsers'] },
  );

  const [groupUnassign] = useMutation(REMOVE_FROM_GROUP,
    { refetchQueries: ['getUsers'] },
  );

  const [uploadBulk] = useMutation(IMPORT_BULK,
    { refetchQueries: ['getUsers'] },
  );

  return { create, setCriteria, enable, disable, remove, unlock, groupAssign, groupUnassign, addUserForm, editUserForm, uploadBulk };
}
