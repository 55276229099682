import {useState} from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {Row, Col} from 'react-bootstrap';
import withAlerts from '../../lib/withAlerts';
import {compose} from 'redux';
import Modal from 'react-bootstrap/Modal';
import HDMConfigFormPresets from './hdmConfigFormPresets';
import {useForm} from 'react-hook-form';
import {useHDMConfigMutations} from './hdmConfigMutations';
import { useLazyQuery, gql } from "@apollo/client";
import {modes} from './hdmPresets';

export const HDMConfigModal = (props) => {
  const {register, reset, handleSubmit, setFocus, setValue, setError, formState: { errors } } = useForm({mode: 'onBlur'});
  const [show, setShow] = useState(false);
  const [noPreset, setNoPreset] = useState(false);
  let {success, error} = props.alerts;
  const {create} = useHDMConfigMutations();
  const [getProfiles] = useLazyQuery(PROFILES);
  const handleShow = () => {
    reset();
    setShow(true);
  }
  const handleClose = () => setShow(false);
  
  const handleSaveConfiguration = async (formData) => {
    
    //Set the sequence number on options first, then remove non-configured presets
    let formattedOptions = formData?.options?.map((option,index) => {
      option.sequence = index;
      option.option_name = modes.find((mode) => mode.predefined_preset == option.predefined_preset).pretty;
      return option;  
    }).filter((option) => (option.predefined_preset > 0 && option.predefined_preset < 8 ));

    let profile = {
      name: formData?.name,
      options: formattedOptions.flat()
    };

    if(profile.options.length === 0) {
      setNoPreset(true);
      setFocus('options.1.predefined_preset');
      return;
    }

    let variables = {
      variables: {
        filters: {
          field: "name", value: `${profile?.name}`
        }
      }
    };

    //lazyQuery
    let checkExistingProfile = await getProfiles(variables);
    
    if(checkExistingProfile?.data?.HDMConfigProfiles?.result?.length > 0) {
      setError("name", {
        type: "manual",
        message: "HDM Profile name already exists.",
      })
      setFocus('name');
      return;
    }
       
    const createProfile = await create({variables: {profile: profile}});
    if(createProfile?.errors) {

      let userFacingError = ('HDM Profile Creation failed.');
      console.error(userFacingError + JSON.stringify(createProfile?.errors));
      error({msg: userFacingError});
      
    } else {
      success({msg: `HDM Profile Added.`});
      handleClose();
    }
  }

  const clearErrors = () => setNoPreset(false);

  return (
    <>
      <Button data-testid="" className="ms-3" onClick={handleShow}>Create HDM Profile</Button>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
        animation={false}
        className="modal-white"
      >
        <Form className="mb-3">
          <Modal.Header closeButton>
            <Modal.Title>Create HDM Profile</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Row>
            <Col>
              <HDMConfigFormPresets 
                register={register}
                setFocus={setFocus}
                errors={errors}
                setValue={setValue}
                noPreset={noPreset}
                clearErrors={clearErrors}
              />
            </Col>
          </Row>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex justify-content-end">
              <Button onClick={handleClose} variant="secondary">Cancel</Button> 
              <Button onClick={handleSubmit(handleSaveConfiguration)} className="ms-2">Submit</Button> 
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

const PROFILES = gql`
query getHDMConfigProfiles($filters: GenericFilters!) {
  HDMConfigProfiles (filters: $filters) {
    result {
      name
    }
  }
}
`;

HDMConfigModal.propTypes = {
  alerts: PropTypes.shape({
    error: PropTypes.func,
    success: PropTypes.func
  })
}

export default compose(
  withAlerts,
)(HDMConfigModal);
