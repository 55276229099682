export const isAuthenticated = () => {
  let authStore = JSON.parse(sessionStorage.getItem('authStore')) || {};
  return authStore?.access_token ? true : false;
}

export const hasAuthSession = () => {
  let authStore = JSON.parse(sessionStorage.getItem('authStore')) || {};
  return authStore?.session_state ? true : false;
}

export const getSessionStore = () => {
  let store = JSON.parse(sessionStorage.getItem('sessionStore')) || {};
  return store;
}

export const setSessionStore = (data) => {
  let store = getSessionStore();
  let newStore = Object.assign(store, data);
  
  sessionStorage.setItem('sessionStore', JSON.stringify(newStore));
}

export const getAuthStore = () => {
  let authStore = JSON.parse(sessionStorage.getItem('authStore')) || {};
  return authStore;
}

export const setAuthStore = (auth) => {
  sessionStorage.setItem('authStore', JSON.stringify(auth));
}

export const resetSessionStore = (data) => {
  sessionStorage.removeItem('sessionStore');
}

export const resetAuthStore = () => {
  sessionStorage.removeItem('authStore');
  localStorage.removeItem('agreement');
}

export const getRefreshToken = () => {
  let authStore = JSON.parse(sessionStorage.getItem('authStore')) || {};
  return authStore && authStore.refresh_token;
}

export const getIDToken = () => {
  let authStore = JSON.parse(sessionStorage.getItem('authStore')) || {};
  return authStore && authStore.id_token;
}

