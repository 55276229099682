import ClientOAuth2 from 'client-oauth2';
import {parse, format} from "native-url";
import {getEnv} from '../../getEnv';
import {getRefreshToken} from './sessionStore';

let client;

export const getClient = async () => {
  if(client) { return client }

  let env = getEnv();
  let discovery_url = `${env.keycloak_host}/realms/${env.keycloak_realm}/.well-known/openid-configuration`;
  let r = await fetch(discovery_url);
  let wellKnown = await r.json();
  
  let config = {
    clientId:env.oauth_client_id,
    accessTokenUri: wellKnown['token_endpoint'],
    authorizationUri: wellKnown['authorization_endpoint'],
    redirectUri: env.oauth_redirect_uri,
    revokeUri: wellKnown['revocation_endpoint'],
    scopes: ['openid']
  };
  client = new ClientOAuth2(config);
  
  // implement endSession
  client.endSessionUrl = (queryParams) => {
    const target = parse(wellKnown.end_session_endpoint, true);
    target.query = Object.assign({
      client_id: env.oauth_client_id,
    }, queryParams);
    return format(target);
  };
  return client;
}

export const getKeycloakPublicKey = async () => {
  if (client) {return client}
  let env = getEnv();
  
  let discovery_url = `${env.keycloak_host}/realms/${env.keycloak_realm}`;
  let r = await fetch(discovery_url);
  let cfg = await r.json();
  return cfg.public_key;
}

export const refreshToken = async () => {
  let refresh_token = getRefreshToken() || '';
  let env = getEnv();

  let refresh_url = `${env.keycloak_host}/realms/${env.keycloak_realm}/protocol/openid-connect/token`;
  let params = {
    client_id: env.oauth_client_id,
    grant_type: 'refresh_token',
    refresh_token: refresh_token
  };
  
  const response = await fetch(refresh_url, {
    method: 'POST',
    body: new URLSearchParams(params),
  });
  
  const json = await response.json();
  return json;
}


