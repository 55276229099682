import {useRef, useState} from 'react';
import {Button, Row, Col, Form} from 'react-bootstrap';
import {usePortalContext} from '../../lib/context/portalContext';
import withAlerts from '../../lib/withAlerts';
import {compose} from 'redux';
import {useQuery, gql } from "@apollo/client";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck, faXmark} from '@fortawesome/pro-solid-svg-icons';
import dateFormat from '../../lib/dateFormat';
import cx from 'classnames';
import {useForm} from 'react-hook-form';
import {useHDMConfigMutations} from '../hdm-configuration/hdmConfigMutations';

const HDMDetails = (props) => {
  const {register, handleSubmit, formState: { errors } } = useForm();
  const {rowData} = usePortalContext();
  const config = rowData?.device_configuration ?? {};
  const existingProfile = rowData?.hdm_config_preset_profile?.[0] ?? {};
  const [currentProfile, setCurrentProfile] = useState(existingProfile);
  const inputRef = useRef();  
  const {hdm_config_preset_profile_id, id, device_state: {hdm_preset_option}} = rowData ?? null;
  const {data} = useQuery(PROFILES);
  const profiles = data?.HDMConfigProfiles?.result || [];
  const [disabled, setDisabled] = useState(true);
  const [showButton, setShowButton] = useState(false);
  const {success, error} = props?.alerts;
  
  const getPreset = () => {
    let option = "N/A";

    if(config?.is_hdm_enabled && hdm_preset_option)
    {
      if(hdm_preset_option == "0") {
        option = 'All Phone Radios Enabled';
      } else {
        option = hdm_preset_option;
      }
    }

    return option; 
  }
  
  const {assign,unassign} = useHDMConfigMutations();

  const handleProfileAssignment = async (formData) => {
    let profile_id = formData?.hdm_config_preset_profile_id;
    
    if(profile_id === "select_profile" || profile_id == undefined) {
      return null;  
    }

    else if(profile_id === "00000000-0000-0000-0000-000000000000") {
      const unassignProfile = await unassign({
        variables: {
          hdm_config_preset_profile_id: `${hdm_config_preset_profile_id}`,
          device_id: `${id}`
        }
      })

      if(unassignProfile?.errors) {
        let userFacingError = ('HDM Profile unassignment failed.');
        console.error(userFacingError + JSON.stringify(unassignProfile?.errors));
        error({msg: userFacingError});
      } else {
        setCurrentProfile({});
        success({msg: `Factory Default Profile assigned.`});
        setShowButton(false);
      }
    
    } else {

      const assignProfile = await assign({
        variables: {
          hdm_config_preset_profile_id: `${profile_id}`,
          device_id: `${id}`
        }
      })

      if(assignProfile?.errors) {
        let userFacingError = ('HDM Profile assignment failed.');
        console.error(userFacingError + JSON.stringify(assignProfile?.errors));
        error({msg: userFacingError});
      } else {
        setCurrentProfile(profiles?.find((p) => p.id == profile_id) || {});
        success({msg: `HDM Profile assigned.`});
        setShowButton(false);
      }
    }
  };

  const handleButton = (v) => ((v !== hdm_config_preset_profile_id && v != 'select_profile') ? setDisabled(false) : setDisabled(true));
  
  return ( <>
    <div className="offcanvas-form" data-testid="offcanvas-safecase">
      <Form className="mb-3" onSubmit={handleSubmit(handleProfileAssignment)}>
        <Row>
          <Col className="mb-3">
            <Form.Group>
              <Form.Label>HDM Profile</Form.Label>
              <div>{currentProfile?.name || 'Factory Default'}</div>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col className="mb-3">
            <Form.Group>
              <Form.Label>HDM Preset: </Form.Label>
              <span> {getPreset()}</span>
            </Form.Group>
          </Col>
        </Row>

        <Row className={cx({'d-none': !showButton})}>
          <Col className="mb-3">
            <Form.Label>Change HDM Profile</Form.Label>
            <Form.Select
              ref={inputRef}
              {...register("hdm_config_preset_profile_id")}
              disabled={!showButton}
              onChange={e => handleButton(e.target.value)}
            > 
              <option key="select_profile" value="select_profile">Select Profile</option>
              <option key="default" disabled={hdm_config_preset_profile_id == '00000000-0000-0000-0000-000000000000'} value="00000000-0000-0000-0000-000000000000">Factory Default</option>
              {profiles && profiles.map(({id, name}, i) => (
                <option key={id} disabled={hdm_config_preset_profile_id == id} value={id}>{name}</option>
              ))}
            </Form.Select>
          </Col>
        </Row>

        <Row className={cx({'d-none': !showButton})}>
          <Col className="mb-3">
            <div className="d-grid gap-2">
              <Button disabled={disabled} type="submit">Assign HDM Profile</Button>
            </div>
          </Col>
        </Row>
        
        <Row className={cx('mb-3', {'d-none': showButton})}>
          <Col>
            <a onKeyDown={() => setShowButton(!showButton)} onClick={() => setShowButton(!showButton)} className={cx({'d-none': showButton}, 'link-span privoro-blue' )}>Change Assigned HDM Profile</a>
          </Col>
        </Row>
      </Form>
    </div>
    </> )
}

const PROFILES = gql`
query getHDMConfigProfiles($filters: GenericFilters) {
  HDMConfigProfiles (filters: $filters) {
    result {
      id
      name
    }
  }
}
`;

export default compose(
  withAlerts,
)(HDMDetails);
