import { React, useState, useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { Button, Row, Col, Form } from 'react-bootstrap';
import { compose } from 'redux';
import { usePortalContext } from '../../lib/context/portalContext';
import withAlerts from '../../lib/withAlerts';
import { useUserMutations } from './userMutations'
import {NIL} from 'uuid';
import { useDeviceMutations } from '../devices/deviceMutations';
import { useLazyQuery, gql } from "@apollo/client";
import { ErrorMessage } from '@hookform/error-message';
import cx from 'classnames';
import {isPossiblePhoneNumber, isValidPhoneNumber} from 'libphonenumber-js';
import {DeleteUserConfirmModal} from './deleteUserConfirmModal'

const UserDetails = (props) => {
  const {togglePortal, rowData, origin} = usePortalContext();
  const {alerts} = props;
  //first form hook
  const { register, reset, handleSubmit, setFocus, formState: { errors } } = useForm({mode: 'onBlur'});
  //second form hook
  const {register: assignmentRegister, handleSubmit: assignmentHandleSubmit, setFocus: assignmentSetFocus} = useForm();
  const [ newUsernameError, setNewUsernameError ] = useState(null);
  const [ usernameError, setUsernameError ] = useState(null);
  const [showUserAssign, setShowUserAssign] = useState(false);
  const { setCriteria } = useDeviceMutations();
  const [getUserID] = useLazyQuery(GET_USERID);
  const [getDeviceByUserID] = useLazyQuery(GET_DEVICE_BY_USERID);
  const {addUserForm, editUserForm, remove} = useUserMutations();

  // reset form with user data after props finish coming in.
  // rowData is updated by portalContext subquery to include 
  // user information
  useEffect(() => {    
    let user = rowData?.user ?? null;
    user && reset({
      username: user.username,
      firstname: user.firstname,
      lastname: user.lastname,
      email: user.email,
      phone: user.phone
    });
  }, [rowData, reset]);

  const handleUnassignUser = async () => {
    const newAssignment = await setCriteria({ variables: 
      {
        serial_number: rowData?.serial_number,
        field: 'user_id',
        value: NIL
      }
    });

    if(newAssignment?.errors) {
      let userFacingError = ('There was an error unassigning the user. Please try again.');
      console.error(userFacingError + JSON.stringify(newAssignment?.errors));
      alerts.error({title: 'Edit Assignment Error', msg: userFacingError});
    } else {
      alerts.success({title: 'Edit Assignment', msg: `User unassigned.`});
    }
    togglePortal();
  }

  const handleAssignUser = async (formData) => {
    setNewUsernameError(null);  
    
    const {newusername} = formData ?? null;
    if(newusername.length === 0) {
      setNewUsernameError('User ID is required.');
      assignmentSetFocus('newusername');
      return;
    }

    if(newusername.length > 0) {
      //build vars
      let variables = {
        variables: {
          filters: {
            field: "username", value: `${newusername}`
          }
        }
      };

      //lazyQuery to verify username exists
      let {data, error} = await getUserID(variables);

      if(data?.Users && data.Users.result.length) {
        let id = data.Users.result[0].id ?? null;

        //userid to assign to safecase
        if (id) {

          let deviceCheckVariables =  {
            variables: {
              filters: {
                combineAnd: [
                  {field: "user_id", value: `${id}` },
                  {field: "with.user", value: "1"},
                ]
              }
            }
          }
          //lazy query to check for assignments
          let {data: dCheck, error: dCheckError} = await getDeviceByUserID(deviceCheckVariables);

          if(dCheck?.Devices) {
            setNewUsernameError('User already assigned to a SafeCase. Please unassign first.');
            assignmentSetFocus('newusername');
            return;
          }
          
          const newAssignment = await setCriteria({
            variables: {
              serial_number: `${rowData?.serial_number}`,
              field: 'user_id',
              value: id
            }
          });

          if (newAssignment?.errors) {
            let userFacingError = ('Unassignment failed');
            console.error(userFacingError + JSON.stringify(newAssignment?.errors));
            alerts.error({ title: 'Edit Assignment Error', msg: userFacingError });
          } else {
            alerts.success({ title: 'Edit Assignment', msg: `User assigned.` });
          }
        }
        togglePortal();
      }

      if(error) {
        //no user found by username search
        if(error?.message.toLowerCase().includes('no user was found with the given filters')) {
          setNewUsernameError('User ID does not exist.')
        }
        else {
          setNewUsernameError(error.message);
        }
        assignmentSetFocus('newusername');
      }
    }
  }

  //attempt to strip duplicate values from user update to prevent excess logging
  function removeDuplicateValues(newObject, originalObject) {
    for (let property in newObject) {
      if (property === "username") continue;
      if (originalObject[property] === newObject[property]) {
          delete newObject[property];
      }
    }
  }
  
  // handle delete user
  const handleDeleteUser = async() => {
    //console.log('deleting user', rowData?.user?.username)
    // todo - open a confirmation Modal which indirectly does this
    const deletedUser = await remove({variables:{username: rowData?.user?.username}});

    if(deletedUser?.errors) {

      let userFacingError = ('There was an error deleting the user. Please try again.');
      console.error(userFacingError + JSON.stringify(deletedUser?.errors));
      alerts.error({title: 'Delete User Error', msg: userFacingError});
      
    } else {
      alerts.success({title: 'Deleted User', msg: `User deleted.`});
      togglePortal();
    }
  }

  // when user hits submit
  const handleSaveUser = async(formData) => {
    setUsernameError(null);
    // if row doesn't have a username, we got here from 'create new user'
    if(!(rowData?.user?.username)) {

      const {username} = formData ?? null;
      //inline error
      if(username.length === 0) {
        setUsernameError('User ID is required.');
        setFocus('username');
        return;
      }

      if(username.length > 0) {
        //build vars
        let variables = {
          variables: {
            filters: {
              field: "username", value: `${username}`
            }
          }
        };

        //lazyQuery
        let {data} = await getUserID(variables);
        if(data?.Users) {
          setUsernameError('User ID already exists.');
          setFocus('username');
          return;   
        }

        //attempt to strip empty values from user create
        for (let property in formData) {
          if (formData[property] === "") {
              delete formData[property];
          }
        }
        
        const newUser = await addUserForm({variables: {user: formData}});

        if(newUser?.errors) {

          let userFacingError = ('There was an error creating the new user. Please try again.');
          console.error(userFacingError + JSON.stringify(newUser?.errors));


          if(JSON.stringify(newUser?.errors).includes('duplicate key value violates unique constraint') ||
          JSON.stringify(newUser?.errors).includes('already exists') ) {
            userFacingError = 'User already exists'
          }
          alerts.error({title: 'Create User Error', msg: userFacingError});
          
        } else {
          alerts.success({title: 'Create User', msg: `User created.`});
          togglePortal();
        }
      }
    }
    // else we're editing a user
    else {

      //strip out the duplicate values
      removeDuplicateValues(formData, rowData?.user);

      const editUser = await editUserForm({variables: {user: formData}})
      if(editUser?.errors) {

        let userFacingError = ('User edit failed');
        console.error(userFacingError + JSON.stringify(editUser?.errors));
        alerts.error({title: 'Edit User Error', msg: userFacingError});
        
      } else {
        alerts.success({title: 'Edit User', msg: `User edited.`});
        togglePortal();
      }
    }
  }

  return (
    <div className="offcanvas-form">
      { ( (origin?.pathname === '/users') || 
      (origin?.pathname === '/devices' && rowData?.user?.username) || 
      (origin?.pathname === '/policy/assignments' && rowData?.user?.username)
      ) && 
      <Form className="mb-3" onSubmit={handleSubmit(handleSaveUser)}>
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label >User ID</Form.Label>
              {/* default comes from clicked row context
              BUT if it was a device page row?
               defaultValue={rowData?.firstname} */}
              <Form.Control
              
              {...register("username", {
                required: "User ID is required.",
                pattern: {
                  value: /^[a-zA-Z0-9-_.]+$/,
                  message: "Invalid User ID - Letters, numbers, hyphens, underscores and periods are acceptable."
                },
                maxLength: {
                  value: 50,
                  message: "Length of User ID exceeds 50 characters."
                }
              })}
                type="text"
                data-testid="username_input"
                placeholder="JohnDoe01" 
                defaultValue={rowData?.user?.username}
                readOnly={rowData?.user?.username}
                className={cx({'error-input': errors?.username || usernameError})}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label >First</Form.Label>
              <Form.Control {...register("firstname", {
                maxLength: {
                  value: 50,
                  message: "Length of First name exceeds 50 characters."
                }})}
                type="fname"
                data-testid="fname_input"
                placeholder="John"
                className={cx({'error-input': errors?.firstname})}
                defaultValue={rowData?.user?.firstname}/>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label >Last</Form.Label>
              <Form.Control {...register("lastname", {

                maxLength: {
                  value: 50,
                  message: "Length of Last name exceeds 50 characters."
                }})}
                type="lname"
                data-testid="lname_input"
                placeholder="Doe"
                className={cx({'error-input': errors?.lastname})}
                defaultValue={rowData?.user?.lastname}/>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                {...register("email", {
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address."
                  },
                  maxLength: {
                    value: 50,
                    message: "Length of Email exceeds 50 characters."
                  }  
                })}
                type="email"
                data-testid="email_input"
                placeholder="name@email.com" 
                defaultValue={rowData?.user?.email} 
                className={cx({'error-input': errors?.email})}
              />
            </Form.Group>
            
            <Form.Group className="mb-3">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                {...register("phone", {
                  validate: {
                    hasAndValid: v => { 
                      return(
                        v.length && (isPossiblePhoneNumber(v) || isPossiblePhoneNumber(v, 'US'))
                      )
                    }
                  }
                })}
              type="phone"
              data-testid="phone_input"
              placeholder="555-555-5555" 
              defaultValue={rowData?.user?.phone}
              className={cx({'error-input': errors?.phone})}
            />
            </Form.Group>
           
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="d-grid gap-2">
                <Button data-testid="submit_input" type="submit">Save Changes</Button>
                 {/* remove this errormessage component */}
                 <ErrorMessage
                  errors={errors}
                  name="email"
                  render={({ message }) => <strong>{message}</strong>}
                />
                {usernameError && <strong>{usernameError}</strong> }
                {errors?.username && <strong>{errors?.username?.message}</strong>}
                {errors?.firstname && <strong>{errors?.firstname?.message}</strong>}
                {errors?.lastname && <strong>{errors?.lastname?.message}</strong>}
                {errors.phone && <strong>Invalid phone number.</strong> }
              </div>
            </Col>
          </Row>
        </Form>
      }
      
      { rowData?.user?.username &&
      <Row className="mb-3">
        { (origin?.pathname === "/users" && rowData?.user?.username) ? 
          <Col>
          <DeleteUserConfirmModal onChange={handleDeleteUser} scope={"user"} 
          message={`Deleting this user will unpair an existing assignment.`} assignmentCount={rowData?.serial_number ? 1 : 0} />
        </Col>
          : null
        }

        { (origin?.pathname !== "/users" && rowData?.user?.username) ? 
          <Col>
            <span className="link-span" onClick={handleUnassignUser}>Unassign User</span>
          </Col>
          : null
        }
      </Row>
      }

      { rowData?.user?.username === undefined &&
        <Row>
          <Col>
          <div className="d-grid gap-2">
            {/* { (origin?.pathname == "/users" && rowData?.user?.username) ? <Button variant="secondary" onClick={handleDeleteUser}>Delete User</Button> : null} */}
            {/* { (origin?.pathname !== "/users" && rowData?.user?.username) ? <Button variant="secondary" onClick={handleUnassignUser}>Unassign User</Button> : null} */}
            { origin?.pathname !== "/users" ? 
              <Button onClick={() => { setShowUserAssign(true);}}
              variant= { showUserAssign ? "secondary" : "primary"}
              >Assign New User
              </Button>
               : null}
          </div>
          </Col>
            
        </Row>
      }

        { /*secondary form for reassigning SC */ origin?.pathname !== "/users" && showUserAssign  &&
          <Form className="mb-3" onSubmit={assignmentHandleSubmit(handleAssignUser)}>
            <Row>
              <Col>
                <div className="d-grid gap-2">
                  <Form.Group className="my-3">
                    <Form.Label >User ID To Assign</Form.Label>
                    <Form.Control  
                      {...assignmentRegister("newusername")}
                      type="text"
                      placeholder="jsmith"
                      className={cx({'error-input': newUsernameError})}
                    />
                  </Form.Group>
                </div>
              </Col>
            </Row>
            
            <Row>
              <Col>
                <div className="d-grid gap-2">
                  <Button type="submit">Confirm New Assignment</Button>
                  { newUsernameError && <strong>{newUsernameError}</strong> }
                </div>
              </Col>
            </Row>
          </Form>
        }
      
    </div>
  );
}

const GET_USERID = gql`
  query getUserId($filters: GenericFilters!) {
    Users (filters: $filters) {
      result {
        id
        username
      }
    }
  }
`;

const GET_DEVICE_BY_USERID = gql`
  query getDeviceByUserID($filters: GenericFilters!) {
    Devices (filters: $filters) {
      result {
        id
      }
    }
  }
`;


//export default UserDetails
export default compose(
  withAlerts
)(UserDetails);
