import {useState} from 'react';
import PropTypes from 'prop-types';
import {Modal, Button, Row, Col} from 'react-bootstrap';

export const DeleteConfirmModal = (props) => {
  const {onChange, title} = props;
  const [showConfirm, setShowConfirm] = useState(false);
  
  const handleShow = () => setShowConfirm(true);
  const handleClose = () => setShowConfirm(false);

  let modalConfirmMessage = props?.message ?? "default message"
  
  const handleConfirm = () => {
    onChange();
    if(showConfirm) {
      handleClose();
    }
  };

  return (
    <>
      <a className="link-span" onKeyDown={handleShow} onClick={handleShow}>Delete {title}</a>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showConfirm}
        onHide={handleClose}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="align-items-center">
            <Col className="align-self-center">
              <b className="text-center">{modalConfirmMessage}</b>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-center">
            <Button onClick={handleClose} variant="secondary">Cancel</Button> 
            <Button onClick={handleConfirm} className="ms-2">Confirm</Button> 
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

DeleteConfirmModal.propTypes = {
  onChange: PropTypes.func,
  title: PropTypes.string,
  message: PropTypes.string
}

DeleteConfirmModal.defaultProps = {
  onChange: ()=>{},
  title: "Delete",
  message: ""
}

export default DeleteConfirmModal;
