import { gql, useMutation } from '@apollo/client';
import {getAuthStore} from './sessionStore';
import {getEnv} from "../../getEnv";

const LOG = gql`
  mutation ($event: EventInput) {
    LogReport(event: $event)
  }
`;

export function useReportingMutations() {
  const [log] = useMutation(LOG);
  return { log };
}

//Manual fetch gql client for use in hooks
export async function logSignoutManually() {
  let env = getEnv();
  const {access_token} = getAuthStore();
  
  //Log the event
  await fetch(env.graphql_endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'authorization': `Bearer ${ access_token }`
    },
    body: JSON.stringify({ query: `
      mutation {
        LogReport(event: {name: "portalLogout"})
      }` 
    }),
  })
}
