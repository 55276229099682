import React, {useState, useEffect} from 'react';
import {useQuery,gql} from "@apollo/client";
import Table from '../table';
import ActionBar from '../actionBar';
import {dateFormatter} from '../dateFormatter';
import { useRecoilValue } from 'recoil';
import { pageSizeRecoil, showDeleted } from '../recoil';
import {usePortalContext} from '../../lib/context/portalContext';
import {useLocation} from 'react-router-dom';
import {useSearch, useSort} from '../../lib/useQueryModifiers';

export const PolicyAssignments = props => {
  const {state:{policyId}} = useLocation();
  const [search, setSearch] = useSearch();
  const [sort, setSort] = useSort();
  const [pageNum, setPageNum] = useState(1);
  const [totalRecordsState, setTotalRecordsState] = useState(0);
  const pageSize = useRecoilValue(pageSizeRecoil);
  
  const {data, loading} = useQuery(DEVICES, 
    {
      variables: {
        filters: {
          combineAnd: [
            ...search,
            ...sort,
            {
              field: "policy_id",
              value: `${policyId}`
            },
            {
              field: "with.user",
              value: "1",
            },
            {
              field: "with.policy",
              value: "1",
            },
            {
              field: "with.firmware",
              value: "1",
            },
            {
              field: "search.fields",
              value: `user.firstname`,
            },
            {
              field: "search.fields",
              value: `user.lastname`,
            },
            {
              field: "search.fields",
              value: `user.username`,
            },
            {
              field: "search.fields",
              value: "serial_number",
            },
            {
              field: "options.page.num",
              value: `${pageNum}`
            },
            {
              field: "options.page.len",
              value: `${pageSize}`
            }

          ]
        }
      },
      skip: !sort.length
    });

  let totalQueried = data?.Devices?.metadata?.page?.records ?? totalRecordsState;

  useEffect(()=> {
    setTotalRecordsState(totalQueried);
  }, [totalQueried]);

  useEffect(() => {
    //attempt callback to set pageNum back to 1
    //races useQuery
    return setPageNum(1)
  }, [search]);

  let devices = data?.Devices?.result ?? [];
  const policyName = devices?.[0]?.policy?.name ?? '';
  //devices = devices.map(device => {return {...device};});
  
  const columns = [
    {
      dataField: 'id',
      hidden: true
    },
    {
      dataField: 'user.firstname',
      text: 'First Name'
    },
    {
      dataField: 'user.lastname',
      text: 'Last Name'
    },
    {
      dataField: 'user.username',
      text: 'User ID'
    },
    {
      dataField: 'serial_number',
      text: 'SAFECASE SERIAL NO.'
    },
    {
      dataField: 'firmware.version',
      text: 'Firmware Version'
    },
    {
      dataField: 'modified_at',
      text: 'Last Updated (UTC)',
      sort: true,
      formatter: dateFormatter,
      hidden: true
    }
  ];

  return (
    <>
      <div className="sticky-top">
        <ActionBar showHidden={false} searchChange={setSearch} title={`Manage ${policyName} Assignments`}>
        </ActionBar>
      </div>
      <Table
        testId="policy-assignments-table"
        columns={columns}
        data={devices}
        loading={loading}
        pageSize={pageSizeRecoil}
        pageNum={pageNum}
        setPageNum={setPageNum}
        totalRecords={totalRecordsState}
        setSort={setSort}
        disableRowClick={true}
      />
    </>
  );
};

const DEVICES = gql`
  query getDevicesPolicyAssignments($filters: GenericFilters!) {
    Devices (filters: $filters) {
      result {
        id
        policy_id
        serial_number
        device_model_id
        policy {
          id
          name
          description
          is_active
          is_phone_protected
          is_offline
          has_battery_warning
          battery_warning_threshold
          is_checked_in
          is_camera_exposed
          is_cellular_connected
          is_microphone_exposed
          is_phone_present
          policy_action_id
          created_at
          modified_at
        }
        user {
          id
          username
          firstname
          lastname
        }
        firmware {
          id
          version
          description
          is_active
        }
        reported_firmware {
          version
        }
        modified_at
      }
      metadata {
        page {
          num
          len
          total
          inPage
          records
        }
      }
    }
  }
`;

export default PolicyAssignments;
