import { useState, useEffect } from 'react';
import ActionBar from '../actionBar';
import { Button } from 'react-bootstrap';
import Portal from '../portal';
import Table from '../table';
import BulkImportComponent from './bulkImport';
import { useQuery, gql } from '@apollo/client'
import { dateFormatter } from '../dateFormatter';
import { useRecoilValue } from 'recoil';
import { pageSizeRecoil, showDeleted } from '../recoil';
import { usePortalContext } from '../../lib/context/portalContext';
import UserDetails from './userDetails';
import {useSearch, useSort} from '../../lib/useQueryModifiers';

export const Users = props => {
  const {togglePortal, rowData, clicked} = usePortalContext();
  const [search, setSearch] = useSearch();
  const [sort, setSort] = useSort();
  const [pageNum, setPageNum] = useState(1);
  const [totalRecordsState, setTotalRecordsState] = useState(0);
  const showingDeleted = useRecoilValue(showDeleted);
  const pageSize = useRecoilValue(pageSizeRecoil);  
  const {data, loading} = useQuery(USERS, 
    {
      variables: {
        filters: {
          combineAnd: [
            ...search,
            ...sort,
            {
              field: "no_display",
              value: "false",
            },
            {
              field: "options.show_deleted",
              value: `${showingDeleted}`,
            },
            {
              field: "with.device", value: "1"
            },
            {
              field: "search.fields",
              value: `firstname`,
            },
            {
              field: "search.fields",
              value: `lastname`,
            },
            {
              field: "search.fields",
              value: `username`,
            },
            {
              field: "options.page.num",
              value: `${pageNum}`
            },
            {
              field: "options.page.len",
              value: `${pageSize}`
            }
          ]
        }
      },
      skip: !sort.length
    });

  let totalQueried = data?.Users?.metadata?.page?.records ?? totalRecordsState;
  
  useEffect(() => {
    //attempt callback to set pageNum back to 1
    //races useQuery
    return setPageNum(1)
  }, [search]);

  useEffect(() => {
    setTotalRecordsState(totalQueried);
  }, [totalQueried]);

  let users = data?.Users?.result ?? [];
  users = users.map(user => { return { ...user, is_delete: showingDeleted }; });

  const columns = [
    {
      dataField: 'id',
      hidden: true
    },
    {
      dataField: 'firstname',
      text: 'First Name'
    },
    {
      dataField: 'lastname',
      text: 'Last Name'
    },
    {
      dataField: 'username',
      text: 'User ID'
    },
    {
      dataField: 'email',
      text: 'Email'
    },
    {
      dataField: 'phone',
      text: 'Phone'
    },
    {
      dataField: 'modified_at',
      text: 'Last Updated (UTC)',
      sort: true,
      formatter: dateFormatter
    }
  ];

  return (
    <>
      <Portal title={clicked === 'importuser' ? "Bulk Import Users":"User"}>
        {(clicked === 'createuser') || (rowData?.user?.username) ?  <UserDetails type="user" title="User" /> : null }
        {clicked === 'importuser' ? <BulkImportComponent type="users" /> : null }
      </Portal>

      <ActionBar showHidden={false} searchChange={setSearch} title="Manage Users">
      <div>
        <Button data-testid="create-user-button" clicked={"createuser"} onClick={togglePortal}>Create User</Button>
        <Button data-testid="import-user-button" className="ms-3" defaultkey={0} clicked={"importuser"} onClick={togglePortal}>Import Users</Button>
      </div>
      </ActionBar>
      <Table
        testId="users-table"
        data={users}
        columns={columns}
        loading={loading}
        pageSize={pageSize}
        pageNum={pageNum}
        setPageNum={setPageNum}
        totalRecords={totalRecordsState}
        setSort={setSort}
      />
    </>
  );
};

const USERS = gql`
  query getUsers($filters: GenericFilters!) {
    Users (filters: $filters) {
      result {
        id
        username
        firstname
        lastname
        email
        phone
        is_active
        created_at
        modified_at
      }
      metadata {
        page {
          num
          len
          total
          inPage
          records
        }
      }
    }
  }
`;

export default Users;
