import {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Alert, Row, Col, Button, Form} from 'react-bootstrap';
import {useQuery,gql} from "@apollo/client";
import {compose} from 'redux';
import {faCircleCheck} from '@fortawesome/sharp-light-svg-icons';
import {faCircleCheck as faCircleCheckSolid} from '@fortawesome/sharp-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import moment from 'moment';
import withAlerts from '../../lib/withAlerts';
import {getSessionStore, setSessionStore, getAuthStore} from '../auth/sessionStore';
import {useForm} from 'react-hook-form';
import {useMessageMutations} from './messageMutations';
import cx from 'classnames';

export const SystemMessages = (props) => {
  const [show, setShow] = useState(true);
  const [hidden, setHidden] = useState([]);
  const [states, setStates] = useState([]);
  const {ignore} = getSessionStore();
  const {acknowledge} = useMessageMutations();
  const {error} = props.alerts;
  const {claims} = getAuthStore();
  const {preferred_username: username} = claims || {};
  const {register, handleSubmit, watch} = useForm();
  let variant = 'warning';
  watch();
  
  const {data} = useQuery(MESSAGES, {variables: {
    filters: {
      combineAnd: [
        {field: "with.system_message_admin_actions", value: "1"}
      ]
    }
  },
  skip: ignore
  });
  const messages = data?.SystemMessages?.result ?? [];
  const ignoreMessages = () => setSessionStore({ignore: true});

  //Message reducer
  const activeMessages = messages.reduce((acc, message) => {
    let msg = {...message};
    let start = msg?.start_time;
    let end = msg?.end_time;
    let now = moment().unix();
    
    let actions = msg.system_message_admin_actions;
    if(!actions.find(a => a?.username === username)) {
      if(now >= start && now <= end) {
        acc.push(msg);
      }    
    }
    return acc;
  }, []);

  useEffect(() => {
    if(activeMessages?.length && !ignore) {
      setShow(true);
    } else {
      setShow(false);
    }   
  }, [activeMessages, ignore])

  const singleLabelClick = (id) => handleSingleItem(id);

  const handleSingleItem = async (id) => {
    let variables = {
      ack: {
        username: username,
        system_message_id: id,
        action: "A"
      }
    }
    const setAcknowledge = await acknowledge({variables});
    
    if(setAcknowledge.errors) {
      error({msg: 'There was an error acknowledging the message. Please try again.'});
    }
    setHidden(hidden => [...hidden, id]);

  }

  const handleItems = async () => {
    setStates(activeMessages.map(({id}) => id));
    activeMessages.forEach( ({id}) => {
      handleSingleItem(id)
    })
    setSessionStore({ignore: true});
  };

  useEffect(() => {
    const sub = watch((value, { name, type }) => setStates(states => value.msgid))
    return () => sub.unsubscribe()
  }, [watch]);

  const formatCategory = category => {
    let cat = "Update"
    switch(category) {
      case 'FW':
        cat = "Firmware Update"
        break;
      case 'MW':
        cat = "Maintenance Window"
        break;
      default:

    }

    return cat;
  }

  const getMessages = () => {
    return activeMessages.map( ({id, message, category}, index, trigger) => (
      <div key={id} className={cx({'hide': hidden.indexOf(id) !== -1})}>  
        <Row className="justify-content-start">
          <Col>
            <label className="icon-label" onClick={() => singleLabelClick(id)}>
              <div className="icon-labels d-inline">
                <Form.Check
                  type="checkbox"
                  value={id}
                  className="d-none"
                  {...register('msgid')}
                />
                <FontAwesomeIcon icon={faCircleCheck} size="lg" className={ cx('inactive-icon', { 'checked': (states.indexOf(id) !== -1) } )} />
                <FontAwesomeIcon icon={faCircleCheckSolid} size="lg" className={ cx('active-icon', { 'checked': (states.indexOf(id) !== -1) } )} color={'black'} />
              </div>
              <div className="d-inline ms-4"><b>{formatCategory(category)}:</b> {message}</div>
            </label>
          </Col>
        </Row>
        <hr/>
      </div>
    ))
  }
  
  if (activeMessages?.length && show) {
    return (
      <Alert id="system-messages" variant={variant} onClose={() => ignoreMessages()} dismissible>
        <Form onSubmit={handleSubmit(handleItems)}>
          <Alert.Heading className="pb-3">Notifications</Alert.Heading>
          {getMessages()}

          { activeMessages?.length > 1 &&
            <Row>
              <Col>
                <Button
                  type="submit"
                  id="acknowledge"
                  className="px-3 btn-black"
                >Acknowledge All</Button>
              </Col>
            </Row>
          }
        </Form>
      </Alert>
    );
  }
  return null;
}

const MESSAGES = gql`  
query getSystemMessages($filters: GenericFilters) {
  SystemMessages(filters: $filters) {
    result {
      id
      category
      message
      start_time
      end_time
      system_message_admin_actions {
        username
      }
    }
  }
}
`;

SystemMessages.propTypes = {
   alerts: PropTypes.shape({
    error: PropTypes.func,
  })
}

export default compose(
  withAlerts
)(SystemMessages);
