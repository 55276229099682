import React, {useState} from 'react';
import {compose} from 'redux';
import ActionBar from '../actionBar';
import {Modal, Button, Row, Col} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import {useTicketMutations} from './ticketMutations';
import withAlerts from '../../lib/withAlerts';
import cx from 'classnames';
import Accordion from 'react-bootstrap/Accordion';

export const Support = (props) => {
    const [ticketName, setTicketName] = useState('');
    const [ticketEmail, setTicketEmail] = useState('');
    const [ticketSubject, setTicketSubject] = useState('');
    const [ticketIssueType, setTicketIssueType] = useState('');
    const [ticketDescription, setTicketDescription] = useState('');
    const [showCreateTicket, setShowCreateTicket] = useState(false);
    const {alerts} = props;
    const [validEmail, setValidEmail] = useState(false);
    const [emailError, setEmailError ] = useState(null);
    const [nameError, setNameError ] = useState(null);
    const [subjectError, setSubjectError ] = useState(null);
    const [descriptionError, setDescriptionError ] = useState(null);
    const [issueTypeError, setIssueTypeError ] = useState(null);

    const files = {
        portal: `/user_agreement/support/Privoro-Fulcrum-Cloud2-Portal-Manual.pdf`,
        cloud: `/user_agreement/support/cloud_release_notes.pdf`,
        ios: `user_agreement/support/ios_release_notes.pdf`,
        firmware: `/user_agreement/support/firmware_release_notes.pdf`,
        android: `/user_agreement/support/android_release_notes.pdf`
      };

    const handleShowCreateTicket = () => {
      resetFields();
      setShowCreateTicket(true);
    }
    const handleClose = () => {
      setShowCreateTicket(false);
    };
    const {createAzureTicket} = useTicketMutations();

    const validationField = (e) => {
        if(e.target.name == 'ticketName') {
            if(e.target.value == '') {
                setNameError("Name is required.");
                return;
            }
            if(e.target.value?.length > 50) {
                setNameError('Length of Name exceeds 50 characters.');
                return;
            }
            setNameError(null);
        } else if(e.target.name == 'ticketSubject') {
            if(e.target.value == '') {
                setSubjectError("Subject is required.");
                return;
            }
            if(e.target.value?.length > 50) {
                setSubjectError('Length of Subject exceeds 50 characters.');
                return;
            }
            setSubjectError(null);
        } else if(e.target.name == 'ticketEmail') {
            if(e.target.value == '') {
                setEmailError("Email is required.");
                return;
            }
            let regEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
            const email = e.target.value;
            if (!regEmail.test(email)) {
                setValidEmail(false);
                setEmailError('Invalid Email.');
            } else {
                setValidEmail(true);
                setEmailError(null);
            }
        } else if(e.target.name == 'ticketDescription') {
            if(e.target.value == '') {
                setDescriptionError("Description is required.");
                return;
            }
            setDescriptionError(null);
        } else if(e.target.name == 'ticketIssueType') {
            if(e.target.value == '') {
                setIssueTypeError("Issue Type is required.");
                return;
            }
            setIssueTypeError(null);
        }
    }

    const onSubmit = async (e) => {
        setNameError(ticketName == '' ? "Name is required.":'');
        setSubjectError(ticketSubject == '' ? "Subject is required.":'');

        if(ticketEmail == '' ) {
            setEmailError('Email is required.');
        } else if(!validEmail) {
            setEmailError('Invalid Email.');
        } 
        setDescriptionError(ticketDescription == '' ? "Description is required.":'');
        setIssueTypeError(ticketIssueType == '' ? "Issue Type is required.":'');
        
        if(!validEmail || emailError || nameError || subjectError || descriptionError || issueTypeError) {
            return;
        }
        
        const createTicket = await createAzureTicket({variables: {
            workItem: {
                work_item_fields: [
                    {
                        "op": "add",
                        "path": "/fields/System.Title",
                        "from": null,
                        "value": ticketSubject.toString()
                      },
                        {
                        "op": "add",
                        "path": "/fields/System.Description",
                        "from": null,
                        //"value": `IssueType: ${ticketIssueType}<br>Description: ${ticketDescription}<br>Name: ${authState.claims.name}<br>Email: ${authState.claims.email}`
                        "value": `IssueType: ${ticketIssueType}<br>Description: ${ticketDescription}<br>Name: ${ticketName}<br>Email: ${ticketEmail}`
                        }                    
                ]
            }
          }});

        if(createTicket?.errors) {
            alerts.error({title: 'Ticket', msg:'There was an error submitting your ticket. Please try again.'});
        } else {
            let azureTicket = JSON.parse(createTicket.data.CreateAzureWorkItem);
            alerts.success({title: 'Ticket', msg:`We've received your message and will email you shortly.`});
        }
        resetFields();
        handleClose();
    }
    
    const resetFields = () => {
        setTicketName('');
        setTicketEmail('');
        setTicketSubject('');
        setTicketIssueType('');
        setTicketDescription('');
        setValidEmail(false);

        setNameError('');
        setSubjectError('');
        setEmailError('');
        setDescriptionError('');
        setIssueTypeError('');
    }

    return (
        <>
            <div className="sticky-top">
                <ActionBar showHidden={false} showSearchBar={true} showSearchInput={false} title="Support & Help">
                    <div>
                        <Button data-testid="create-ticket-button" className="ms-3" defaultkey={0} onClick={handleShowCreateTicket}>Create a Support Ticket</Button>
                    </div>
                </ActionBar>
            </div>
            <div className="support">
                <Row style={{paddingTop:"3rem"}}>
                    <Col sm="7" style={{paddingTop:"1rem", paddingBottom:"1rem"}}>
                        <div className="section-header">Frequently Asked Questions</div>
                    </Col>
                    <Col sm="5" className="section-right"  style={{paddingTop:"1rem", paddingBottom:"1rem"}}>
                      <div className="section-header">Recent Updates</div>
                    </Col>
                </Row>
                <Row>
                    <Col sm="7">
                        <Accordion flush defaultActiveKey="0" alwaysOpen>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                    Getting Started
                                </Accordion.Header>
                                <Accordion.Body>
                                    <a href={files.portal} target="_blank">Privoro Portal Administrator Guide</a>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                    <Col sm="5" className="section-right" style={{paddingBottom:"1rem"}}>
                        <Accordion flush defaultActiveKey="0" alwaysOpen>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Privoro Fulcrum Cloud Release</Accordion.Header>
                                <Accordion.Body>
                                    <a href={files.cloud} target="_blank">Cloud Release Notes</a>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>
                                    Privoro iOS App Release
                                </Accordion.Header>
                                <Accordion.Body>
                                    <a href={files.ios} target="_blank">iOS App Release Notes</a>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>
                                    Privoro Android App Release
                                </Accordion.Header>
                                <Accordion.Body>
                                    <a href={files.android} target="_blank">Android App Release Notes</a>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>
                                    SafeCase Firmware Release
                                </Accordion.Header>
                                <Accordion.Body>
                                    <a href={files.firmware} target="_blank">Firmware Release Notes</a>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                </Row>
            </div>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <Modal
                {...props}
                data-testid="create-ticket-popup"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={showCreateTicket}
                onHide={handleClose}
                className="modal-white"
                animation={false}
            >
            <Modal.Header
                closeButton
                closeVariant="white"
            >
                <h4>Create a Ticket</h4>
            </Modal.Header>
            <Modal.Body>
                    <Row>
                        <Col>
                        <Form.Group className="mb-3">
                            <Form.Control name="ticketName" placeholder="Name" 
                             onChange={e => setTicketName(e.target.value)}
                             onBlur={validationField}
                             className={cx({'error-input': nameError})}
                            />
                        </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <Form.Group className="mb-3">
                            <Form.Control name="ticketEmail" placeholder="Email" 
                             onChange={e => setTicketEmail(e.target.value)}
                             onBlur={validationField}
                             className={cx({'error-input': emailError})}
                            />
                        </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <Form.Group className="mb-3">
                            <Form.Control name="ticketSubject" placeholder="Subject" 
                             onChange={e => setTicketSubject(e.target.value)}
                             onBlur={validationField}
                             className={cx({'error-input': subjectError})}
                            />
                        </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <Form.Group className="mb-3">
                        <Form.Select name="ticketIssueType" placeholder="Issue Type"
                            onChange={e => setTicketIssueType(e.target.value)}
                            onBlur={validationField}
                            className={cx({'error-input': issueTypeError}, {'select-gray-opton': ticketIssueType == '', 'select-black-opton': ticketIssueType != ''})}>
                            <option value="" default hidden>Issue Type</option>
                            <option value="SafeCase">SafeCase</option>
                            <option value="Mobile App">Mobile App</option>
                            <option value="Portal">Portal</option>
                            <option value="Other">Other - Billing, Licensing, Login etc.</option>
                        </Form.Select>
                        </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <Form.Group>
                            <Form.Control name="ticketDescription" placeholder="Description"
                             as="textarea" rows="5" onChange={e => setTicketDescription(e.target.value)}
                             onBlur={validationField}
                             className={cx({'error-input': descriptionError})}
                            />
                        </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="modal-footer justify-content-start">
                        <Button data-testid="popup-submit-button" onClick={onSubmit} className="btn btn-primary">Submit</Button>    
                        {nameError && <strong>{nameError}</strong>}
                        <div className="flex-break"></div>
                        {emailError && <strong>{emailError}</strong>}
                        <div className="flex-break"></div>
                        {subjectError && <strong>{subjectError}</strong>}
                        <div className="flex-break"></div>
                        {issueTypeError && <strong>{issueTypeError}</strong>}
                        <div className="flex-break"></div>
                        {descriptionError && <strong>{descriptionError}</strong>}
                        </Col>
                    </Row>
            </Modal.Body>
        </Modal>
        </>
      );
}

export default compose(withAlerts)(Support);
