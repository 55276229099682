import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';
import {Modal, Button, Row, Col} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPencil} from '@fortawesome/pro-light-svg-icons';
import Form from 'react-bootstrap/Form';
import {usePolicyMutations} from './policyMutations';
import withAlerts from '../../lib/withAlerts';
import {compose} from 'redux';
import dateFormat from '../../lib/dateFormat';

const PolicyInfoModal = (props) => {
  const {cell, row: policy} = props;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showNameModal, setShowNameModal] = useState(false);
  const handleCloseNameModal = () => setShowNameModal(false);
  const handleShowNameModal = () => setShowNameModal(true);

  const {success, error} = props.alerts;
  const {setCriteria} = usePolicyMutations();
  const inputRef = useRef([]);
  const nameRef = useRef();

  const updateDescription = async () => {
    let newVal = inputRef.current.value;

    if(policy.description === newVal) return;

    const setPolicyCriteria = await setCriteria({variables: 
      {
        name: policy.name,
        field: 'description',
        value: newVal
      }
    });

    if(setPolicyCriteria.errors) {
      error('Error setting policy criteria.');
    } else {
      success('Policy criteria updated.');
    }
  }

  const updateName = async () => {
    let newVal = nameRef.current.value;
    if(policy.name === newVal) return;

    const setPolicyCriteria = await setCriteria({variables: 
      {
        name: policy.name,
        field: 'name',
        value: newVal
      }
    });

    if(setPolicyCriteria.errors) {
      error('Error setting policy criteria.');
    } else {
      success('Policy criteria updated.');
    }
  }

  return (
    <>
      <span>
        <FontAwesomeIcon icon={faPencil} size="lg" className={policy.is_delete ? 'me-2' : 'me-2 pointer privoro-blue'} style={policy.is_delete ? {'visibility': 'hidden'} : {}} onClick={handleShowNameModal}/>
        <b className={`pointer privoro-blue`} onClick={handleShow}>{cell}</b>
      </span>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {policy.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  <Row className="align-items-center">
                    <Col>
                      <b>Description</b>
                    </Col>
                    <Col>
                      <Form.Control ref={inputRef} type="text" size="sm" onBlur={updateDescription} defaultValue={policy.description} />
                    </Col>
                  </Row>
                </li>
                <li className="list-group-item">
                  <Row>
                    <Col sm="6">
                      <b>Status</b>
                    </Col>
                    <Col>
                      {policy.is_active ? 'Active' : 'Disabled'}
                    </Col>
                  </Row>
                </li>
                <li className="list-group-item">
                  <Row>
                    <Col>
                      <b>Rules</b>
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col sm="5" className="offset-sm-1">
                      Unprotected Alert
                    </Col>
                    <Col>
                      {policy.is_camera_exposed || policy.is_microphone_exposed ? 'Active' : 'Disabled'}
                    </Col>
                  </Row> */}
                  <Row>
                    <Col sm="5" className="offset-sm-1">
                      Geofence
                    </Col>
                    <Col>
                      {policy.geofence_group_id !== "00000000-0000-0000-0000-000000000000" ? 'Active' : 'Disabled'}
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col sm="5" className="offset-sm-1">
                      Check-in
                    </Col>
                    <Col>
                      {policy.is_checked_in ? 'Active' : 'Disabled'}
                    </Col>
                  </Row> */}
                  <Row>
                    <Col sm="5" className="offset-sm-1">
                      Battery Warning
                    </Col>
                    <Col>
                      {policy.has_battery_warning ? 'Active' : 'Disabled'}
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col sm="5" className="offset-sm-1">
                      Offline Warning
                    </Col>
                    <Col>
                      {policy.is_offline ? 'Active' : 'Disabled'}
                    </Col>
                  </Row> */}
                  <Row>
                    <Col sm="5" className="offset-sm-1">
                      Cellular Violation
                    </Col>
                    <Col>
                      {policy.is_cellular_connected ? 'Active' : 'Disabled'}
                    </Col>
                  </Row>
                </li>
                <li className="list-group-item">
                  <Row>
                    <Col>
                      <b>Geofence Group</b>
                    </Col>
                    <Col>
                      {policy.geofence_group_id}
                    </Col>
                  </Row>
                </li>
                <li className="list-group-item">
                  <Row>
                    <Col>
                      <b>Created (UTC)</b>
                    </Col>
                    <Col>
                      {dateFormat(policy.created_at)}
                    </Col>
                  </Row>
                </li>
                <li className="list-group-item">
                  <Row>
                    <Col>
                      <b>Last Updated (UTC)</b>
                    </Col>
                    <Col>
                      {dateFormat(policy.modified_at)}
                    </Col>
                  </Row>
                </li>
              </ul>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showNameModal}
        onHide={handleCloseNameModal}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Policy Name</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Control defaultValue={policy.name} ref={nameRef}/>
            </Col>
          </Row>          
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-end">
            <Button onClick={handleCloseNameModal} variant="secondary">Cancel</Button> 
            <Button onClick={updateName} className="ms-2">Submit</Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export const infoFormatter = (cell, row) => {
  return (
    <PolicyInfoModal 
      cell={cell}
      row={row}
    />
  );
}


PolicyInfoModal.propTypes = {
  row: PropTypes.object
};

export default compose(
  withAlerts
)(PolicyInfoModal);
