import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';
import {Modal, Button, Row, Col} from 'react-bootstrap';
import {useQuery,gql} from "@apollo/client";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPencil} from '@fortawesome/pro-light-svg-icons';
import Form from 'react-bootstrap/Form';
import withAlerts from '../../lib/withAlerts';
import {compose} from 'redux';
import { usePolicyMutations } from './policyMutations';

export const PolicyGroupAssignmentModal = (props) => {
  const {data} = useQuery(POLICY_GRP);
  const {cell, row: {id, is_delete}} = props;
  const currentGroup = props.row?.group?.name;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const {success, error} = props.alerts;
  const policyGroups = data?.PolicyGroups ?? [];


  const inputRef = useRef(currentGroup);
  const {groupAssign, groupUnassign} = usePolicyMutations();

  const onSubmit = async () => {
    let newVal = inputRef.current.value;
    if(newVal === currentGroup) 
    {
      success('Group assigned.');
      handleClose();
      return;
    }
    
    if (newVal === 'unassign') {
      const unassignToGrp = await groupUnassign({
        variables:
        {
          group_type: "policy",
          group_name: currentGroup,
          member_id: id
        }
      });

      if (unassignToGrp.errors) {
        let userFacingError = ('Group assignment failed');
        console.error(userFacingError + JSON.stringify(unassignToGrp?.errors));
        error(userFacingError);
      } else {
        success('Group unassigned.');
        handleClose();
      }

    }
    else {
      const addToGrp = await groupAssign({variables: 
        {
          group_type: "policy",
           group_name: newVal,
            member_id: id }
        
      });

      if (addToGrp.errors) {
        let userFacingError = ('Group assignment failed');
        error(userFacingError);
      } else {
        success('Group assigned.');
        handleClose();
      }
    }
    
    
    
    
    
  }

  return (
    <>
      <span>
        <FontAwesomeIcon icon={faPencil} size="lg" className={is_delete ? 'me-2' : 'me-2 pointer privoro-blue'} style={is_delete ? {'visibility': 'hidden'} : {}} onClick={handleShow}/>{cell}
      </span>
      <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Policy Assignment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Row>
          <Col>
            <Form.Select ref={inputRef}>
              <option>Select policy group</option>
              {currentGroup && <option value="unassign">Unassign from {currentGroup}</option>}
              { policyGroups.map(({id, name}, i) => (
                <option key={i} value={name}>{name}</option>
              ))}
            </Form.Select>
          </Col>
        </Row>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-end">
            <Button onClick={handleClose} variant="secondary">Cancel</Button> 
            <Button onClick={onSubmit} className="ms-2">Submit</Button> 
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const POLICY_GRP = gql`
  query getPolicyGroups {
    PolicyGroups(filters:{field: "is_active", value: "true"}){
      id
      name
    }
  }
`;

PolicyGroupAssignmentModal.propTypes = {
  row: PropTypes.object,
  cell: PropTypes.string
};

export default compose(
  withAlerts
)(PolicyGroupAssignmentModal);
