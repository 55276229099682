import React, {useState, useRef} from 'react';
import {getEnv} from "../../getEnv";
import {useAgreementMutations} from './agreementMutations';
import {getAuthStore} from '../auth/sessionStore';
import { useQuery, gql } from "@apollo/client";
import {Row, Col, Form} from 'react-bootstrap';
import {useForm} from "react-hook-form";
import {v4 as uuidv4} from 'uuid';

export const UserAgreement = (props) => {
  const {children} = props;
  const [hasAgreementCheck, setHasAgreementCheck] = useState(false);
  const hasAgreement = localStorage.getItem('agreement') ? true:false;
  const [signed, setSigned] = useState(hasAgreement);
  const {signAgreement} = useAgreementMutations();
  const [testVer, setTestVer] = useState(uuidv4())
  const {claims} = getAuthStore();
  const preferred_username = claims?.preferred_username || '';
  const {register, setFocus, handleSubmit} = useForm();
  
  const files = {
    privacy: `/user_agreement/privoro_privacy_policy_v0-0-1.pdf`,
    terms: `/user_agreement/Terms_of_Use_v0-0-1.pdf`,
    eula: `/user_agreement/Privoro_EndUser_LicenseAgreement_v0-0-1.pdf`
  };
  
  let env = getEnv();
  const agreement_version = env?.agreement_version === 'test' ? testVer : env.agreement_version;

  const {data,loading} = useQuery(AGREEMENT, {
    variables: {
      filters: {
        combineAnd: [
          {
            field: "username",
            value: `${preferred_username}`
          },
          {
            field: "agreement",
            value: `${agreement_version}`
          },
        ]          
      }
    },
    skip: hasAgreement //skip the query if localStorage has agreement
  });

  let agreements = data?.Agreements?.result ?? null;
  if(agreements && agreements.length) {
    localStorage.setItem('agreement', 'signed');
    setSigned(true);
  }
  
  const submitAgreement = async (formData) => {

    let payload = {
      username: `${preferred_username}`,
      agreement: `${agreement_version}`
    };
    
    const signedAgreement = await signAgreement({variables: {agreement: payload}});
    
    if(signedAgreement?.errors) {
      // What are we doing if this mutation returns an error
      // an already signed agreement is a duplicate key error
      localStorage.removeItem('agreement');
      setSigned(false);
      setFocus('sign_agreements');
    } else {
      localStorage.setItem('agreement', 'signed');
      setSigned(true);
    }

    if(signedAgreement) {
      localStorage.setItem('agreement', 'signed');
      setSigned(true);
    }
  }

  //Halt this page from loading before query has run
  if(!hasAgreement && loading) return null;

  //Show the agreements if we don't see it in storage or query
  if(!hasAgreement) {
    return (
      <div className="d-flex h-100 ">
        <div className="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">
          <main className="px-3 my-auto">
            <Form onSubmit={handleSubmit(submitAgreement)}>
              <Row>
                <Col>
                <p>Please view, save or print the documents linked below.</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <label>
                    <Form.Check 
                      type="checkbox"
                      className="d-inline me-3 mb-1"
                      id="sign_agreements"
                      {...register("sign_agreements")}
                      onChange={() => setHasAgreementCheck(!hasAgreementCheck)}
                    />
                    By clicking <strong>Agree and Continue</strong>, I hereby:
                  </label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <ul>
                    <li>Agree and consent to the <a href={files.eula} target="_blank">End User License Agreement</a>, <a href={files.terms} target="_blank">Terms of Use</a>, and the <a href={files.privacy} target="_blank">Privacy Policy</a></li>
                    <li>These documents are designed to inform you of your rights and obligations when using Privoro services.</li>
                  </ul>      
                </Col>
              </Row>
              <Row>
                <Col>
                  <button
                    type="submit"
                    id="sign_agreement_btn"
                    className="btn btn-primary px-3"
                    disabled={!hasAgreementCheck}
                  >Agree and Continue</button>
                </Col>
              </Row>
            </Form>
          </main>
        </div>              
      </div>
    );
  }
  
  //Return the children if agreements are good.
  return (children);
}

const AGREEMENT = gql`
query getAgreements($filters: GenericFilters) {
  Agreements (filters: $filters) {
    result {
      id
      account_id
      username
      agreement
      timestamp
      created_at
      modified_at
    }
  }
}`;

export default UserAgreement;
