import {useState} from 'react';
import PropTypes from 'prop-types';
import {Modal, Button, Row, Col} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPencil, faTimes} from '@fortawesome/pro-light-svg-icons';
import {faSpinnerThird} from '@fortawesome/pro-duotone-svg-icons';
import Form from 'react-bootstrap/Form';
import {useDeviceMutations} from './deviceMutations';
import withAlerts from '../../lib/withAlerts';
import {compose} from 'redux';
import cx from 'classnames';
import {NIL} from 'uuid';

export const FirmwareAssignmentModal = (props) => {
  const {
    row: {
      serial_number,
      firmware_id,
      firmware,
      reported_firmware_id,
      reported_firmware,
      ota_state,
      ota_progress,
      is_active,
    }
  } = props ?? {};
  
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => is_active && setShow(true);
  
  const [showCancelConfirm, setShowCancelConfirm] = useState(false);
  const handleHideCancelConfirm = (e) => {
    e?.stopPropagation();
    setShowCancelConfirm(false);
  }
  const handleShowCancelConfirm = (e) =>  {
    e?.stopPropagation();
    setShowCancelConfirm(true);
  }
  const {success, error} = props.alerts;
  const {stopOTA} = useDeviceMutations();

  const onCancel = async (e) => {
    e?.stopPropagation();
    handleHideCancelConfirm();
    const stopDeviceOTA = await stopOTA({variables:{serial_number}});
    
    if(stopDeviceOTA.errors) {
      error({title: 'Cancel Firmware Update', msg: 'Error canceling firmware update.'});
    } else {
      success({title: 'Cancel Firmware Update', msg: 'Firmware update canceled.'});
      handleClose();      
    }
  }

  const showCancel = (ota_state === "in_progress" || ( (reported_firmware_id !== firmware_id) && (firmware_id !== NIL && reported_firmware_id !== NIL ) ));
  const showSpinner = ota_state === "in_progress";
  
  return (
    <>
      { ota_state === 'in_progress' ?
        <>
          <b className="privoro-white me-2" style={{fontWeight:'normal', fontSize: 'x-small'}}>
            {ota_progress}%
          </b> 
          { showCancel && <FontAwesomeIcon onClick={handleShowCancelConfirm} color="red" icon={faTimes} size="lg" className="me-2 pointer"/>}
          { showSpinner && <FontAwesomeIcon icon={faSpinnerThird} size="lg" className="me-2 fa-spin"/>}
          <span>{firmware?.version ?? ''}</span>
        </>
        :
        <>
          { showCancel && <FontAwesomeIcon onClick={handleShowCancelConfirm} color="red" icon={faTimes} size="lg" className="me-2 pointer"/>}
          <span>{reported_firmware?.version ?? ''}</span>
        </>
      }
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showCancelConfirm}
        onHide={handleHideCancelConfirm}
        animation={false}
      >
        <Modal.Header >
          <Modal.Title>Cancel Firmware Update</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Row>
          <Col>
            <b>Cancel Firmware Update From {reported_firmware?.version ?? "unknown"} To {firmware?.version ?? "unknown"}?</b>
          </Col>
        </Row>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-end">
            <Button onClick={handleHideCancelConfirm} variant="secondary">Cancel</Button> 
            <Button onClick={onCancel} className="ms-2">Confirm</Button> 
          </div>
        </Modal.Footer>
      </Modal>      
    </>
  );
}

FirmwareAssignmentModal.propTypes = {
  row: PropTypes.object
};


export default compose(
  withAlerts
)(FirmwareAssignmentModal);
