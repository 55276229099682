import {useState, useEffect} from 'react';
import {useQuery,gql} from "@apollo/client";
import Table from '../table';
import ActionBar from '../actionBar';
import {dateFormatter} from '../dateFormatter';
import {useRecoilValue } from 'recoil';
import {pageSizeRecoil, showDeleted } from '../recoil';
import {usePortalContext} from '../../lib/context/portalContext';
import {FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCheck, faXmark, faCalendarClock } from '@fortawesome/pro-light-svg-icons';
import {faCircle } from '@fortawesome/pro-solid-svg-icons';
import variables from '../../styles/_variables.module.scss';
import Portal from '../portal';
import LogDetails from './logDetails';
import {useSort} from '../../lib/useQueryModifiers';
import CancelConfirmModal from './cancelConfirmModal';

export const Logs = props => {
  const [sort, setSort] = useSort();  
  const [pageNum, setPageNum] = useState(1);
  const [totalRecordsState, setTotalRecordsState] = useState(0);
  const {togglePortal} = usePortalContext();
  const pageSize = useRecoilValue(pageSizeRecoil);
  
  const {data, loading} = useQuery(JOBS,{pollInterval: 30000,  variables: {
    "filters": {
      "combineAnd": [
        ...sort,
        {
          "field": "options.sort_fields",
          "value": "created_at.dsc"
        },
        {
          field: "options.page.num",
          value: `${pageNum}`
        },
        {
          field: "options.page.len",
          value: `${pageSize}`
        }
      ]
    }
  },
  skip: !sort.length
});

  let totalQueried = data?.Jobs?.metadata?.page?.records ?? totalRecordsState;

  useEffect(()=> {
    setTotalRecordsState(totalQueried);
  }, [totalQueried]);


  let jobs = data?.Jobs?.result ?? [];

  const columns = [
    {
      dataField: 'id',
      hidden: true,
    },
    {
      dataField: 'type',
      text: 'Job Type',
    },
    {
      dataField: 'status',
      text: 'Status',
      formatter: (cell, row) => {
        let jobStates = {
          N: {
            stage: 'In Queue',
            icon: faCalendarClock,
            color: variables.grayDark,
          },
          P: {
            stage: 'Processing',
            icon: faXmark,
            percentage: Math.floor((row.processed_count / row.item_count) * 100),
          },
          C: {
            stage: 'Processed',
            icon: faCheck,
            color: variables.privoroBlue
          },
          X: {
            stage: 'Canceled',
            icon: faXmark,
            color: variables.privoroRed 
          },
          S: {
            stage: 'Paused',
            icon: faXmark,
            color: variables.grayDark
          },
          Y: {
            stage: 'Canceling...',
            icon: faXmark,
            color: variables.privoroRed
          }
        };
        let state = jobStates[cell];

        if (cell === 'N' || cell === 'P' || cell === 'S') {
          return (
            <CancelConfirmModal percentage={state.percentage} jobid={row.id} state={state} type={row.type} cell={cell} />
          );
        };
        return (
          <div>
            <span className="fa-layers fa-fw fa-lg me-4">
              <FontAwesomeIcon icon={faCircle} size="2x" color={state.color} />
              <FontAwesomeIcon transform="shrink-1 right-6" icon={state.icon} color="white"/>
            </span>
            {state.stage}
          </div>
        );
      }
    },
    {
      dataField: 'created_at',
      text: 'Job Submission (UTC)',
      formatter: dateFormatter
    },
    {
      dataField: 'modified_at',
      text: 'Last Updated (UTC)',
      formatter: dateFormatter,
      sort: true
    }
  ];
  
  return (
    <>
      <Portal title="Log">
        <LogDetails title="Job Status / Logs"/>
      </Portal>
     
      <ActionBar showHidden={false} showSearchBar={false} title="Logs" />
      
      <Table
        testId="logs-table"
        columns={columns}
        data={jobs}
        loading={loading}
        pageSize={pageSize}
        pageNum={pageNum}
        setPageNum={setPageNum}
        setSort={setSort}
        totalRecords={totalRecordsState}
      />
    </>
  );
};

const JOBS = gql`
query getJobs ($filters: GenericFilters) {
  Jobs(filters: $filters) {
    result {
      id
      status
      type
      item_count
      processed_count
      success_count
      created_at
      modified_at
    }
    metadata {
      page {
        num
        len
        total
        inPage
        records
      }
    } 
  }
}
`;

export default Logs;
