import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Row, Col, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTowerBroadcast } from '@fortawesome/pro-light-svg-icons';
import Form from 'react-bootstrap/Form';
import { usePolicyMutations } from './policyMutations';
import withAlerts from '../../lib/withAlerts';
import { compose } from 'redux';


export const CellularServiceModal = (props) => {
  const { name, is_cellular_connected } = props.row;
  const [isCellularConnected, setIsCellularConnected] = useState(is_cellular_connected);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { success, error } = props.alerts;
  const inputRef = useRef();
  const { setCriteria } = usePolicyMutations();
  const colClassName = isCellularConnected ? "pointer privoro-blue" : "pointer default-rule";

  const onSubmit = async () => {
    let newVal = inputRef.current.value;
    if (newVal === isCellularConnected) return;

    let setPolicyCriteria = {};
    try {
      setPolicyCriteria = await setCriteria({
        variables:
        {
          name: name,
          field: 'is_cellular_connected',
          value: newVal
        }
      });
    } catch (error) {
      setPolicyCriteria.errors = error;
    }

    if (setPolicyCriteria.errors) {
      error('Error setting policy criteria.');
      handleClose();
    } else {
      success('Policy criteria updated.');
      handleClose();
      setIsCellularConnected(!isCellularConnected);
    }
  }

  return (
    <>
      <Col onClick={handleShow} className={colClassName}>
        <OverlayTrigger
          delay={800}
          key="cellTooltip"
          placement={'top'}
          overlay={
            <Tooltip id="tooltip-cell">
              Toggle Cellular from {isCellularConnected ? `Enable` : `Disable`}
            </Tooltip>
          }
        >
          <span>
            <FontAwesomeIcon icon={faTowerBroadcast} size="2x" />
          </span>
        </OverlayTrigger>
      </Col>
      <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Cellular Violation Policy</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Select defaultValue={isCellularConnected} ref={inputRef}>
                <option value="true">Enable</option>
                <option value="false">Disable</option>
              </Form.Select>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-end">
            <Button onClick={handleClose} variant="secondary">Cancel</Button>
            <Button onClick={onSubmit} className="ms-2">Submit</Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

CellularServiceModal.propTypes = {
  row: PropTypes.object
};

export default compose(
  withAlerts
)(CellularServiceModal);
