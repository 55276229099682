import {Navigate} from 'react-router-dom';
import {Main as Layout} from "../layout"
import {useIdleTimer} from 'react-idle-timer';
import {endKeycloakSession} from './signOut';
import {isAuthenticated, setSessionStore} from './sessionStore';

export function PrivateRoute ({ Component, ...rest }) {
  let authenticated = isAuthenticated();
    
  const onIdle = () => {
    setSessionStore({sessionStatus:"timeout"});
    endKeycloakSession();
  }

  const {
    getRemainingTime,
  } = useIdleTimer({
    onIdle,
    timeout: 1000 * 60 * 15, //ms
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange'
    ],
    eventsThrottle: 200,
    stopOnIdle: true //stop the timer so it doesn't loop
  });

  if(authenticated)  {
    return <Layout><Component/></Layout>; 
  }

  return <Navigate to={'/signin'} replace />;
}
