import {Row, Col, Form} from 'react-bootstrap';
import {PropTypes} from 'prop-types';
import {usePortalContext} from '../../lib/context/portalContext';
import {useDeviceMutations} from './deviceMutations';
import withAlerts from '../../lib/withAlerts';
import {compose} from 'redux';
import FirmwareSelect from './firmwareSelect';

const SafeCaseDetails = props => {
  const {togglePortal, rowData} = usePortalContext();
  const {startOTA} = useDeviceMutations();
  const {alerts} = props;

  const {
    serial_number,
    device_model_id,
    id,
    firmware,
    reported_firmware,
    ota_state,
    product_type
  } = rowData ?? {};


  const handleFirmwareChange = async (desiredFID) => {
    if(desiredFID && desiredFID !== firmware?.id) {


      const startDeviceOTA = await startOTA({variables: 
        {
          serial_number: serial_number,
          firmware_id: desiredFID
        }
      });

      if(startDeviceOTA.errors) {
        alerts.error({msg: 'Error updating firmware.'});
      } else {
        alerts.success({msg: 'Firmware update scheduled.'});
      }  
    }
    togglePortal();
    return null;    
  }

  return (
    <div className="offcanvas-form" data-testid="offcanvas-safecase">
      { serial_number && 
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label >Serial Number</Form.Label>
              <Form.Control data-testid="sc-offcanvas-serial-number" placeholder="684A341" defaultValue={serial_number} readOnly={true} />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label >SafeCase Model</Form.Label>
              <Form.Control data-testid="sc-offcanvas-sc-model" placeholder="M0001" defaultValue={product_type?.description} readOnly={true} />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label >Current SafeCase Firmware</Form.Label>
              <Form.Control placeholder="1.0.0.0" defaultValue={reported_firmware?.version} readOnly={true} />
            </Form.Group>
            <FirmwareSelect
              firmware={reported_firmware}
              deviceModelID={device_model_id}
              deviceId={id}
              onChange={handleFirmwareChange}
              otaState={ota_state}
            />
          </Col>
        </Row>
      }      
    </div>
  )
}

SafeCaseDetails.propTypes = {
  alerts: PropTypes.shape({
    success: PropTypes.func,
    error: PropTypes.func
  })
}

export default compose(
  withAlerts
)(SafeCaseDetails);
