import {gql, useMutation} from '@apollo/client';

const CREATE_GROUP = gql`
  mutation ($group_type: GroupTypes!, $group_name: String!) {
    AddGroup(group_type: $group_type, group_name: $group_name)
  }
`;

const RENAME_GROUP = gql`
  mutation ($group_type: GroupTypes!, $group_name: String!, $new_group_name: String!) {
    RenameGroup(group_type: $group_type, group_name: $group_name, new_group_name: $new_group_name)
  }
`;

const ENABLE_GROUP = gql`
  mutation ($group_type: GroupTypes!, $group_name: String!) {
    EnableGroup(group_type: $group_type, group_name: $group_name)
  }
`;

const DISABLE_GROUP = gql`
  mutation ($group_type: GroupTypes!, $group_name: String!) {
    DisableGroup(group_type: $group_type, group_name: $group_name)
  }
`;

const REMOVE_GROUP = gql`
  mutation ($group_type: GroupTypes!, $group_name: String!) {
    RemoveGroup(group_type: $group_type, group_name: $group_name)
  }
`;

export const  ASSIGN_TO_GROUP = gql`
mutation ($group_type: GroupTypes!, $group_name: String!, $member_id: String!) {
  AssignToGroup(group_type: $group_type, group_name: $group_name, member_id: $member_id)
}
`;

export const REMOVE_FROM_GROUP = gql`
mutation ($group_type: GroupTypes!, $group_name: String!, $member_id: String!) {
  UnassignFromGroup(group_type: $group_type, group_name: $group_name, member_id: $member_id)
}
`;
 
export function useGroupMutations () {

  const [create] = useMutation(CREATE_GROUP,
    { refetchQueries: ['getGroups'] },
  );

  const [rename] = useMutation(RENAME_GROUP,
    { refetchQueries: ['getGroups'] },
  );

  const [enable] = useMutation(ENABLE_GROUP,
    { refetchQueries: ['getGroups'] },
  );

  const [disable] = useMutation(DISABLE_GROUP,
    { refetchQueries: ['getGroups'] },
  );

  const [remove] = useMutation(REMOVE_GROUP,
    { refetchQueries: ['getGroups'] },
  );

  const [assignTo] = useMutation(ASSIGN_TO_GROUP,
    { refetchQueries: ['getGroups'] },
  );

  const [removeFrom] = useMutation(REMOVE_FROM_GROUP,
    { refetchQueries: ['getGroups'] },
  );

  return { create, rename, enable, disable, remove, assignTo, removeFrom };
}
