import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faXmark } from '@fortawesome/pro-light-svg-icons';



export const SearchInput = (props) => {
  const ref = useRef(null);
  
  const handleClear = (e) => {
    e.preventDefault();
    if(ref.current.value !== '') {
      ref.current.value = '';
      props.onChange();
    }
  } 

  return (
    <InputGroup id="search-input-group">
      <InputGroup.Text id="mag">
        <FontAwesomeIcon icon={faMagnifyingGlass} size="lg" />
      </InputGroup.Text>
      <Form.Control ref={ref} data-testid="search-box" aria-describedby="mag" type="text" className="search-input" placeholder={props.placeholder} onChange={event => props.onChange(event?.target?.value)}/>
      <InputGroup.Text id="clear">
        <FontAwesomeIcon className="pointer" icon={faXmark} size="lg" onClick={handleClear} />
      </InputGroup.Text>
    </InputGroup>
    
  );
};

SearchInput.defaultProps = {
  placeholder: 'Search'
}

SearchInput.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func
}

export default SearchInput;
