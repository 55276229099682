import {useState, useEffect} from 'react';
import {useDebounce} from 'use-debounce';

export const useSearch = (initialState = []) => {
  const [searchKey, setSearch] = useDebounce(null, 1000);
  const [search, setSearchObject] = useState(initialState);
    
  useEffect(() => {
    if(searchKey && searchKey !== '') {
      let tmp = [{
        field: 'search.q',
        value: `${searchKey}`
      }];
      setSearchObject(tmp);
    } else {
      setSearchObject(initialState);
    }
  }, [searchKey]);
  
  return [search, setSearch];
}

export const useSort = (initialState = []) => {
  const [sortKey, setSort] = useState(null);
  const [sort, setSortObject] = useState(initialState);
  

  useEffect(() => {
    if(sortKey) {
      //mod for dbaas
      let order = sortKey?.sortOrder === 'desc' ? 'dsc' : sortKey.sortOrder;
      let tmp = [{
        field: 'options.sort_fields',
        value: `${sortKey.sortField}.${order}`
      }];

      setSortObject(tmp);
    } else {
      setSortObject(initialState);
    }
  }, [sortKey]);
  
  return [sort, setSort];
}

export const useFilter = (initialState = []) => {
  const [filterKey, setFilter] = useState(null);
  const [filter, setFilterObject] = useState(initialState);
  const [orFilter, setOrFilterObject] = useState(initialState);
  
  useEffect(() => {
    let trigger = filterKey?.filter_one ?? 'all';

    switch(trigger) {
      case 'all':
        setFilterObject(initialState);
        setOrFilterObject(initialState);
        break;

      case 'policy_violations':
        setFilterObject(initialState);
        setOrFilterObject(JSON.parse(filterKey.filter_two));  
        break;

      default:
        setFilterObject([JSON.parse(filterKey.filter_one)]);
        setOrFilterObject(initialState);
        break;
    }

  }, [filterKey]);

  
  
  return [filter, setFilter, orFilter];
}
