import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';
import {Modal, Button, Row, Col, Tooltip, OverlayTrigger} from 'react-bootstrap';
import {useQuery,gql} from "@apollo/client";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLocationDot} from '@fortawesome/pro-light-svg-icons';
import Form from 'react-bootstrap/Form';
import {usePolicyMutations} from './policyMutations';
import withAlerts from '../../lib/withAlerts';
import {compose} from 'redux';
import cx from 'classnames';


export const GeofenceModal = (props) => {
  const {data} = useQuery(GEOFENCE_GROUPS);
  const {name, geofence_group_id} = props.row;
  const [show, setShow] = useState(false);
  const inputRef = useRef();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const {success, error} = props.alerts;
  const geofenceGroups = data?.GeofenceGroups ?? [];
  
  const groupSize = geofenceGroups.reduce((acc, group) => {
    if (group.id === geofence_group_id) {
      acc = group.group_size;
    }
    return acc;
  }, null);

  const {setCriteria} = usePolicyMutations();
  
  const onSubmit = async () => {
    let newVal = inputRef.current.value;
    if(newVal === geofence_group_id) return;
    newVal = newVal === 'disable' ? '11111111-1111-1111-1111-111111111111' : newVal;
    
    const setPolicyCriteria = await setCriteria({variables: 
      {
        name: name,
        field: 'geofence_group_id',
        value: newVal
      }
    });
    
    if(setPolicyCriteria.errors) {
      error('Error setting policy criteria.');
      handleClose();
    } else {
      success('Policy criteria updated.');
      handleClose();      
    }   
  }

  return (
    <>
      <Col onClick={handleShow} className={cx('pointer', {'privoro-blue': groupSize}, {'default-rule': groupSize === null})}>
        <OverlayTrigger
          delay={800}
          key="geoTooltip"
          placement={'top'}
          overlay={
            <Tooltip id="tooltip-geo">
              Geofence
            </Tooltip>
          }
        >  
          <span>
            <FontAwesomeIcon icon={faLocationDot} size="2x"/>
            <b className="rule-sub">{groupSize}</b>
          </span>
        </OverlayTrigger>
      </Col>
      <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Geofence Groups</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Row>
          <Col>
            <Form.Select defaultValue={geofence_group_id} ref={inputRef}>
              <option>Select the Geofence Group</option>
              {groupSize > 0 && <option value="disable">Disable</option>}
              { geofenceGroups.map(({id, name, group_size}) => (
                <option key={id} value={id}>{name} {group_size > 0 ? `(${group_size})` : ''}</option>
              ))}
            </Form.Select>
          </Col>
        </Row>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-end">
            <Button onClick={handleClose} variant="secondary">Cancel</Button> 
            <Button onClick={onSubmit} className="ms-2">Submit</Button> 
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const GEOFENCE_GROUPS = gql`
  query getGeofenceGroups {
    GeofenceGroups {
      id
      account_id
      name
      group_type
      description
      is_active
      created_at
      modified_at
      member_count
    }
  }
`;

GeofenceModal.propTypes = {
  row: PropTypes.object
};

export default compose(
  withAlerts
)(GeofenceModal);
