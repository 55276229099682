import {useState, useEffect} from 'react';
import ActionBar from '../actionBar';
import {compose} from 'redux';
import Table from '../table';
import {useQuery, gql} from "@apollo/client";
import {useDeviceMutations} from './deviceMutations';
import {firmwareAssignmentFormatter, modelFormatter, fulcrumShield, hdmShield} from './columnFormatters';
import {dateFormatter} from '../dateFormatter';
import {useRecoilValue} from 'recoil';
import {pageSizeRecoil, showDeleted} from '../recoil';
import Portal from '../portal';
import {usePortalContext} from '../../lib/context/portalContext';
import withAlerts from '../../lib/withAlerts';
import SafeCaseDetails from './safeCaseDetails';
import SubscriptionDetails from './subscriptionDetails';
import UserDetails from '../users/userDetails';
import PolicyAssignment from '../policy/policyAssignment';
import HDMDetails from './hdmDetails';
import {Button} from 'react-bootstrap';
import BulkOtaDetails from './bulkOtaDetails';
import {useSearch, useSort} from '../../lib/useQueryModifiers';


export const Devices = props => {
  const {rowData, togglePortal, isShowing, clicked} = usePortalContext();
  const {alerts} = props;
  const [search, setSearch] = useSearch();
  const [sort, setSort] = useSort();
  const [pageNum, setPageNum] = useState(1);
  const [totalRecordsState, setTotalRecordsState] = useState(0);
  const showingDeleted = useRecoilValue(showDeleted);
  const pageSize = useRecoilValue(pageSizeRecoil);
  const {data,loading} = useQuery(DEVICES, 
    {
      variables: {
        filters: {
          combineAnd: [
            ...search,
            ...sort,
            {
              field: "options.show_deleted",
              value: `${showingDeleted}`,
            },
            {
              field: "with.device_state", value: "1"
            },
            {
              field: "with.product_type", value: "1"
            },
            {
              field: "with.reported_firmware", value: "1"
            },
            {
              field: "with.firmware", value: "1"
            },
            {
              field: "with.user", value: "1"
            },
            {
              field: "with.policy", value: "1"
            },
            {
              field: "with.device_configuration", value: "1"
            },
            {
              field: "with.hdm_config_preset_profile", value: "1"
            },
            {
              field: "search.fields",
              value: `serial_number`,
            },
            {
              field: "search.fields",
              value: `user.username`,
            },
            {
              field: "search.fields",
              value: `policy.name`,
            },
            {
              field: "options.page.num",
              value: `${pageNum}`
            },
            {
              field: "options.page.len",
              value: `${pageSize}`
            }
          ]
        }
      },
      // devices q polling added for OTA updates
      pollInterval: 8000,
      skip: !sort.length
    });

  // if this doesn't come back (fail case), it's safer to let them page the first 100*10 per
  let totalQueried = data?.Devices?.metadata?.page?.records ?? totalRecordsState;

  useEffect(()=> {
    setTotalRecordsState(totalQueried);
  }, [totalQueried]);

  useEffect(() => {
    //attempt callback to set pageNum back to 1
    //races useQuery
    return setPageNum(1)
  }, [search]);

  let devices = data?.Devices?.result ?? [];

  //Setting defaulyKey to empty string closes all accordion drawers in off-canvas
  devices = devices.map(device => {
    return { ...device, is_delete: showingDeleted, defaultKey: ""};
  });

  const columns = [
    {
      dataField: 'serial_number',
      text: 'SAFECASE SERIAL NO.'
    },
    // {
    //   dataField: 'device_configuration.is_anti_surveillance_enabled_expiry',
    //   text: 'Fulcrum',
    //   formatter: fulcrumShield
    // },
    // {
    //   dataField: 'device_configuration.is_hdm_enabled_expiry',
    //   text: 'HDM',
    //   formatter: hdmShield
    // },
    {
      dataField: 'product_type.description',
      text: 'MODEL',
      formatter: modelFormatter
    },
    {
      dataField: 'reported_firmware.version',
      text: 'Firmware',
      formatter: firmwareAssignmentFormatter
    },
    {
      dataField: 'user.username',
      text: 'USER ID'
    },
    {
      dataField: 'policy.name',
      text: 'Policy'
    },
    {
      dataField: 'modified_at',
      text: 'Last Updated (UTC)',
      sort: true,
      formatter: dateFormatter
    }
  ];

  return (
    <>
      <Portal title="SafeCase">
        {rowData?.serial_number && <SafeCaseDetails type="safecase" title="SafeCase"/>}
        {rowData?.serial_number &&  <UserDetails type="user" title="User"/> }
        {rowData?.serial_number && <PolicyAssignment type="policy" title="Policy" /> }
        {rowData?.product_type?.greyhound_family && <HDMDetails type="hdm" title="HDM Profile"/> }
        {
          //rowData?.serial_number &&  <SubscriptionDetails type="policy" title="Subscriptions"/>
        }
        {clicked === 'bulkota' && <BulkOtaDetails type="safecase" title="Update All Firmware" /> }
      </Portal>

      <ActionBar showHidden={false} searchChange={setSearch} title="Manage SafeCases">
        <div>
          <Button data-testid="bulk-ota" clicked={'bulkota'} defaultkey={0} onClick={togglePortal}>Update Firmware for all SafeCases</Button>
        </div>
      </ActionBar>
      <Table
        testId='safecases-table'
        data={devices}
        columns={columns}
        loading={loading}
        pageSize={pageSize}
        pageNum={pageNum}
        setPageNum={setPageNum}
        totalRecords={totalRecordsState}
        setSort={setSort}
      />
    </>
  );
};

const DEVICES = gql`
  query getDevices($filters: GenericFilters!) {
    Devices (filters: $filters) {
      result {
        id
        policy_id
        device_model_id
        serial_number
        mcu_id
        is_active
        ota_state
        ota_progress
        modified_at
        hdm_config_preset_profile_id
        hdm_config_preset_profile {
          name
          id
        }
        device_state {
          hdm_preset_option
        }
        device_configuration {
          is_hdm_enabled
          is_hdm_enabled_expiry
          is_hdm_enabled_end_date
          is_anti_surveillance_enabled
          is_anti_surveillance_enabled_expiry
          is_anti_surveillance_enabled_end_date
        }
        user {
          id
          username
          firstname
          lastname
          email
          phone
          is_active
          modified_at
        }
        policy {
          id
          name
          geofence_group_id
          description
          is_active
          is_delete
          is_phone_protected
          is_offline
          has_battery_warning
          battery_warning_threshold
          is_checked_in
          is_camera_exposed
          is_cellular_connected
          is_microphone_exposed
          is_phone_present
          policy_action_id
          created_at
          modified_at
        }
        product_type_id
        product_type {
          id
          description
          greyhound_family
          samsung_family
        }
        firmware_id
        firmware {
          id
          version
          description
          is_active
        }
        reported_firmware_id
        reported_firmware {
          id
          version
          description
        }
      }
      metadata {
        page {
          num
          len
          total
          inPage
          records
        }
      }
    }
  }
`;

export default compose(withAlerts)(Devices);
