import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';

const Footer = () => (
  <Row>
    <Col className="footer-legal" data-testid="legal-text">
      <div className="legal-text">
        <strong>You are accessing an Information System (IS) that is provided for USG-authorized use only. By using this IS (which includes any device attached to this IS), you consent to the following conditions:</strong>
        <ol>
          <li>Communication is routinely intercepted and monitored for purposes including, but not limited to, penetration testing, COMSEC monitoring, network operations and defense, personnel misconduct (PM), law enforcement (LE), and counterintelligence (CI) investigations.</li>
          <li>At any time, the data stored on this IS may be seized and inspected.</li>
          <li>Communications using, or data stored on, this IS are not private, are subject to routine monitoring, interception, and search, and may be disclosed or used for any authorized purpose.</li>
          <li>This IS includes security measures (e.g., authentication and access controls) to protect USG interests--not for your personal benefit or privacy.</li>
          <li>Notwithstanding the above, using this IS does not constitute consent to PM, LE or CI investigative searching or monitoring of the content of privileged communications, or work product, related to personal representation or services by attorneys, psychotherapists, or clergy, and their assistants. Such communications and work product are private and confidential.</li>
        </ol>
         
        <strong>Data is Protected by the Privacy Act of 1974: All Users must:</strong>
        <ol>
          <li>Ensure the data is not posted, stored, or available in any way for uncontrolled access on any media.</li>
          <li>Ensure that data is protected at all times as required by the Privacy Act of 1974 (5 USC 552a(I)(3)) as amended and other applicable regulatory and statutory authority; data will not be shared with unauthorized personnel and contractors; data from the application, or any information derived from the application, shall not be published, disclosed, released, revealed, shown, sold, rented, leased or loaned to anyone outside of the performance of official duties without prior approval by authorized representatives.</li>
          <li>Delete or destroy data from downloaded or printed reports upon completion of the requirement for their use on individual projects.</li>
          <li>Ensure distribution of data from this application is restricted to those with a need-to-know. In no case shall data be shared with persons or entities that do not provide documented Proof of a need-to-know.</li>
          <li>Be aware that criminal penalties under section 1106(a) of the Social Security Act (42 USC 1306(a)), including possible imprisonment, may apply with respect to any disclosure of information in the application that is inconsistent with the terms of application access. The user further acknowledges that criminal penalties under the Privacy Act (5 USC 552a(I)(3)) may apply if it is determined that the user has knowingly and willfully obtained access to the application under false pretenses.</li>
        </ol>

        <strong>Under the Privacy Act of 1974, You Must Safeguard Personnel Information Retrieved Through This System.</strong>
      </div>
    </Col>
  </Row>
);

export default Footer;
