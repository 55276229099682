import { gql, useMutation } from '@apollo/client';

const CREATE_AZURE_TICKET = gql`
  mutation($workItem: WorkItemInput!) 
  {
    CreateAzureWorkItem(workItem: $workItem)
  }
`;

export function useTicketMutations() {

  const [createAzureTicket] = useMutation(CREATE_AZURE_TICKET,
    {  },
  );

  return { createAzureTicket };
}
