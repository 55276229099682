import React, { useState, useEffect } from 'react';
import {Row,Col} from 'react-bootstrap';
import Moment from 'react-moment';
import {getAuthStore} from './auth/sessionStore';


const PageHeader = () => {
  const {claims} = getAuthStore();
  const {preferred_username: username} = claims || {};
  const [titleUsername, setTitleUsername] = useState('');

  useEffect(()=>{
    if(username) {
      setTitleUsername(username);
    }
  }, [username]);

  return (
    <div id="page-header" data-testid="page-header" className="p-3 sticky-top">
      <Row className="justify-content-end">
        <Col sm="auto" className="align-self-center">
          <Moment data-testid="timestamp" interval={1000} utc format="MMM DD, YYYY HH:mm:ss"/> UTC
        </Col>
        <Col sm="auto" className="align-self-center username ms-3">
          {titleUsername}
        </Col>
      </Row>  
    </div>
  );
};

export default PageHeader;
