import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import Dashboard from './components/dashboard';
import Devices from './components/devices';
import Users from './components/users';
import Logs from './components/logs';
import Policies from './components/policy';
import PolicyAssignments from './components/policy/assignments';
import {SignIn} from './components/auth/signIn';
import {Callback} from './components/auth/callback';
import {SignOut} from "./components/auth/signOut";
import {PrivateRoute} from "./components/auth/privateRoute";
import {Empty as EmptyLayout} from "./components/layout";
import {NotFound} from "./components/notFound";
import Support from "./components/support";
// import Account from "./components/subscriptions";
// import SHKeys from "./components/hdm-keys";
import HDMConfig from "./components/hdm-configuration";
import HDMAssignments from "./components/hdm-configuration/hdmAssignments";

const routes = [
  {
    path: "/signin",
    Component: SignIn,
    unprotected: true
  },{
    path: "/callback",
    Component: Callback,
    unprotected: true
  },{
    path: "/signout",
    Component: SignOut,
    unprotected: true
  },
  {
    path: "/",
    Component: Dashboard
  },
  {
    path: "/devices",
    Component: Devices
  },
  {
    path: "/policies",
    Component: Policies
  },
  {
    path: "/policy/assignments",
    Component: PolicyAssignments
  },
  {
    path: "/users",
    Component: Users
  },
  {
    path: "/logs",
    Component: Logs
  },
  {
    path: "/support",
    Component: Support
  },
  // {
  //   path: "/subscriptions",
  //   Component: Account
  // },
  // {
  //   path: "/hdm-keys",
  //   Component: SHKeys
  // },
  {
    path: "/hdm-configuration",
    Component: HDMConfig
  },
  {
    path: "/hdm/assignments",
    Component: HDMAssignments
  },
];


export default function App() {
  return (
    <Router>
      <Routes>
        {routes.map((route_info, i) => {
          let {Component, unprotected, path} = route_info;
          if(unprotected) {
            return (
              <Route
                key={i}
                path={path}
                element={
                  <EmptyLayout>
                    <Component/>
                  </EmptyLayout>
                }
              />
            )
          }

          return (
            <Route
              key={i}
              path={path}
              element={
                <PrivateRoute Component={Component} />
              }
            />
          )
        })}
        <Route path="*" element={<PrivateRoute Component={NotFound} />} />
      </Routes>
    </Router>
  );
}
