import {Link} from 'react-router-dom';
import {getClient} from "./client";
import {Container, Row, Col, FormGroup} from 'react-bootstrap';
import {getEnv} from "../../getEnv";
import {ReactComponent as Logo} from '../../assets/portal_logo.svg';
import Button from 'react-bootstrap/Button';
import Footer from './footer';
import {isAuthenticated} from './sessionStore';

export const SignIn = () => {
  let authenticated = isAuthenticated();
  let env = getEnv();  

  const login = async () => {
    let client = await getClient();
    let domain = env.keycloak_realm || '';
    let q = {
      kc_idp_hint: env.keycloak_idp_hint || domain,
      redirect_uri: env.oauth_redirect_uri
    };
    let authUrl = client.code.getUri();
    let url = new URL(authUrl);
    let searchParams = url.searchParams;
    for (const [key, val] of Object.entries(q)) {
      searchParams.set(key, val);
    }
    let href = authUrl.substr(0,authUrl.indexOf("?")) + "?" + searchParams.toString();
    try{
      window.location.assign(href);  
    } catch(err) {
      console.log(err);
    }    
  }
  
  return (
    <Container fluid className="signin" data-testid="login">
      <Row>
        <Col>
          <Row className="py-5">
            <Col className="text-center">
              <div className="login-logo mx-auto">
                <Logo />
              </div>

              {authenticated &&
                <div>
                  <p>You&#39;re already signed in.</p>
                  <p>Do you want to <Link to={'/signout'}>sign out?</Link></p>
                </div>
              }

              {!authenticated &&  
                <FormGroup>
                  <Button data-testid="button-signin" type="submit" className="px-5" onClick={login}>Sign In</Button>
                </FormGroup>
              }
            </Col>
          </Row>
          <Footer />
        </Col>
      </Row>
    </Container>
  )
}
