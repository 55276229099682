import {Form, Row, Col} from 'react-bootstrap';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {modes, presetOptionsPretty} from './hdmPresets';
import {ErrorMessage} from '@hookform/error-message';

export const HDMConfigFormPresets = (props) => {
  const {register, setValue, errors, noPreset, clearErrors} = props;
  const setPresetOptions = (predefined_preset, position) => {
    
    //Select the correct mode
    let mode = modes.find((mode)=> mode.predefined_preset == predefined_preset);
    //Get preset options
    let {options} = mode;
    clearErrors();

    return options.map((opt) => {
      for (const [k, v] of Object.entries(opt)) {
        let name = `options.${position}.${k}`;
        return setValue(`${name}`, v);
      }
    });
  }

  const buildOptions = (p) => {
    return presetOptionsPretty.map((opt) => {
      for (const [k, v] of Object.entries(opt)) {
        return (
          <Form.Check
            disabled
            key={`${k}_${p}`}
            data-testid={`${k}_${p}`}
            type="switch"
            label={`${v}`}
            id={`${k}_${p}`}
            value="false"
            {...register(`options.${p}.${k}`)}
          />
        );       
      }
    })
  }

  const getPresetSelect = (p) => {
    return (
      <>
        <Form.Label>Preset Option {p}</Form.Label>
        <Form.Group className="mb-3">
          <Form.Select
            {...register(`options.${p}.predefined_preset`,
              {valueAsNumber: true})}
            onChange={e => setPresetOptions(e.target.value, p)}
            className={cx({'error-input': noPreset})}
          >
            {modes?.map((m) => (
              <option key={m.predefined_preset} value={m.predefined_preset}>{m.pretty}</option>
              ))}
          </Form.Select>
        </Form.Group>
      </>
    );
  }

  return (
    <div id="hdm-config-form" className="p-3 modal-form-white" data-testid="hdm-config-form">
      <Form.Group className="mb-5">
        <Form.Label>Profile Name</Form.Label>
        <Form.Control
          {...register("name", {
            required: "Profile Name is required.",
            maxLength: {
            value: 50,
            message: "Length of Profile Name exceeds 50 characters."
          }})}
          type="text"
          placeholder="Profile Name"
          className={cx({'error-input': errors?.name})}
        />
      </Form.Group>

      <Row>
        <Col>
          {getPresetSelect(1)}
          <div className="check-container">
            {buildOptions(1)}
          </div>
        </Col>
        <Col>
          {getPresetSelect(2)}
          <div className="check-container">
            {buildOptions(2)}
          </div>
        </Col>
      </Row>
      <Row>
        <Col style={{color: 'black'}}>
          <ErrorMessage
            errors={errors}
            name="name"
            render={({ message }) => <strong>{message}</strong>}
          /><br/>
          {noPreset && <strong>At least 1 preset option required.</strong>}
        </Col>
      </Row>
    </div>

  );
}

HDMConfigFormPresets.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  errors: PropTypes.shape({
    name: PropTypes.any,
  }),
  noPreset: PropTypes.bool,
  clearErrors: PropTypes.func
}

export default HDMConfigFormPresets;
