import {useState, useEffect} from 'react';
import Table from '../table';
import ActionBar from '../actionBar';
import {useQuery, gql} from "@apollo/client";
import {dateFormatter} from '../dateFormatter';
import {useRecoilValue} from 'recoil';
import {pageSizeRecoil} from '../recoil';
import {useLocation} from 'react-router-dom';
import {useSearch, useSort} from '../../lib/useQueryModifiers';

export const HDMAssignments = props => {
  const {state:{hdmId, title}} = useLocation();
  const [search, setSearch] = useSearch();
  const [sort, setSort] = useSort();
  const [pageNum, setPageNum] = useState(1);
  const [totalRecordsState, setTotalRecordsState] = useState(0);
  const pageSize = useRecoilValue(pageSizeRecoil);

  const {data,loading} = useQuery(DEVICES, 
    {
      variables: {
        filters: {
          combineAnd: [
            ...search,
            ...sort,
            {
              field: "with.product_type", value: "1"
            },
            {
              field: "with.reported_firmware", value: "1"
            },
            {
              field: "with.user", value: "1"
            },
            {
              field: "hdm_config_preset_profile_id", value: `${hdmId}`
            },
            {
              field: "with.hdm_config_preset_profile", value: "1"
            },
            {
              field: "search.fields",
              value: `serial_number`,
            },
            {
              field: "search.fields",
              value: `user.firstname`,
            },
            {
              field: "search.fields",
              value: `user.lastname`,
            },
            {
              field: "options.page.num",
              value: `${pageNum}`
            },
            {
              field: "options.page.len",
              value: `${pageSize}`
            }
          ]
        }
      },      
      skip: !sort.length
    });

  let totalQueried = data?.Devices?.metadata?.page?.records ?? totalRecordsState;
  let assignments = data?.Devices?.result || [];
  
  useEffect(()=> {
    setTotalRecordsState(totalQueried);
  }, [totalQueried]);

  useEffect(() => {
    //attempt callback to set pageNum back to 1
    //races useQuery
    return setPageNum(1)
  }, [search]);
    
  const columns = [
    {
      dataField: 'id',
      hidden: true
    },
    {
      dataField: 'user.firstname',
      text: 'First Name'
    },
    {
      dataField: 'user.lastname',
      text: 'Last Name'
    },
    {
      dataField: 'user.username',
      text: 'User ID'
    },
    {
      dataField: 'model',
      text: 'Model'
    },
    {
      dataField: 'serial_number',
      text: 'SAFECASE SERIAL NO.'
    },
    {
      dataField: 'reported_firmware.version',
      text: 'Firmware Version'
    },
    {
      dataField: 'modified_at',
      text: 'Last Updated (UTC)',
      sort: true,
      formatter: dateFormatter,
      hidden: true
    }
  ];

  return (
    <>
      <div className="sticky-top">
        <ActionBar showHidden={false} searchChange={setSearch} title={title}>
        </ActionBar>
      </div>
      <Table
        testId="hdm-profile-assignments-table"
        columns={columns}
        data={assignments}
        loading={loading}
        pageSize={pageSizeRecoil}
        pageNum={pageNum}
        setPageNum={setPageNum}
        totalRecords={totalRecordsState}
        setSort={setSort}
        disableRowClick={true}
      />
    </>
  );
};

const DEVICES = gql`
  query getDevices($filters: GenericFilters!) {
    Devices (filters: $filters) {
      result {
        id
        device_model_id
        serial_number
        mcu_id
        is_active
        modified_at
        user {
          id
          username
          firstname
          lastname
        }
        product_type_id
        product_type {
          id
          description
        }
        reported_firmware {
          id
          version
          description
        }
        hdm_config_preset_profile {
          name
        }
      }
      metadata {
        page {
          num
          len
          total
          inPage
          records
        }
      }
    }
  }
`;

export default HDMAssignments;
