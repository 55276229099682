import {useQuery, gql} from "@apollo/client";

export const useBulkOTACheck = () => {
  const {data, loading} = useQuery(JOBS, {
    variables: {
      filters: {
        combineAnd: [
          {
            field: "type",
            value: "DEVICE_FW_UPGRADE"
          }
        ],
        combineOr: [
          {
            field: "status",
            value: "N"
          },
          {
            field: "status",
            value: "P"
          },
          {
            field: "status",
            value: "S"
          },
          {
            field: "status",
            value: "Y"
          }
        ]
      }
    }
  });
  
  if(!loading) {
    return data && data?.Jobs?.result?.length > 0;
  }
};

export const useAnyOTACheck = () => {
  const {data, loading} = useQuery(DEVICES, {
    variables: {
      filters: {
        field: "ota_state",
        value: "in_progress"
      }
    }
  });

  if(!loading) {
    return data && data?.Devices?.result?.length > 0;
  }
};



const JOBS = gql`
query getJobs ($filters: GenericFilters) {
  Jobs(filters: $filters) {
    result {
      id
      status
      type
    }
  }
}
`;

const DEVICES = gql`
query getDevices($filters: GenericFilters!) {
  Devices (filters: $filters) {
    result {
      id
      is_active
      ota_state
      ota_progress
      modified_at
    }
  }
}
`;
