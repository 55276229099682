import React from 'react'
import { Button, Row, Col, Form } from 'react-bootstrap';
import { usePortalContext } from '../../lib/context/portalContext';
import { useQuery, gql } from "@apollo/client";

const LogDetails = props => {

  const {togglePortal, isShowing, rowData, origin} = usePortalContext();

  const jobID = rowData?.id ?? null;
  const jobComplete = rowData?.status === 'C' || rowData?.status === 'X' || rowData?.status === 'S';

  const { data } = useQuery(GET_JOB_CHUNKS,
    {
      variables: {
        filters: {
          field: "bulk_action_job_id",
          value: jobID
        }
      },
      skip: !jobID
    }
  );

  const { data: log } = useQuery(GET_JOB_LOG,
    {
      variables: {
        filters: {
          field: "jobID",
          value: jobID
        }
      },
      skip: !jobID || !jobComplete
    }
  );

  const job = data?.JobChunks?.result || null;
  const filename = `log_${job?.[0]?.bulk_action_job_id}.csv`;
  let fileBuffer = log?.JobLog || [];
  
  const saveTemplateAsFile = (filename, job) => {
    
    const blob = new Blob([job], { type: "text/csv" });
    const link = document.createElement("a");

    link.download = filename;
    link.href = window.URL.createObjectURL(blob);
    link.dataset.downloadurl = ["text/csv", link.download, link.href].join(":");

    const evt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });

    link.dispatchEvent(evt);
    link.remove()
  };


  return (
    <div className="offcanvas-form">
      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Number of Items Processed</Form.Label>
            <p>{rowData?.processed_count} / {rowData?.item_count}</p>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Success Count</Form.Label>
            <div>{rowData?.success_count}</div>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Failure count</Form.Label>
            <div>{(rowData?.processed_count - rowData?.success_count) ?? ''}</div>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Label>Bulk Operation Log</Form.Label>
          <div className="d-grid gap-2">
            <Button
              data-testid="download-button"
              variant="primary"
              disabled={!job || !jobComplete}
              onClick={() => saveTemplateAsFile(filename, fileBuffer)}
              >Download Log</Button>
          </div>
        </Col>
      </Row>
    </div>
  )
};

const GET_JOB_CHUNKS = gql`
query getJobChunks($filters: GenericFilters) {
  JobChunks(filters: $filters) {
    result {
      id
      bulk_action_job_id
      scope
      status
    }
    metadata {
      page {
        num
        len
        total
        inPage
        records
      }
    }
  }
}
`;

const GET_JOB_LOG = gql`
query getJobLog($filters: GenericFilters) {
  JobLog(filters: $filters)
}
`;

export default LogDetails;
