import React, {useState} from 'react'
import { Button, Row, Col, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useUserMutations } from './userMutations';
import withAlerts from '../../lib/withAlerts';
import { compose } from 'redux';
import { FileUploader } from 'react-drag-drop-files';
import { usePortalContext} from '../../lib/context/portalContext';

const BulkImportComponent = props => {
  const { alerts: {error, success} } = props;
  const { uploadBulk } = useUserMutations();
  const { togglePortal } = usePortalContext();
  const [fileContent, setFileContent] = useState('');
  const [fileName, setFileName] = useState('');
  const [isImporting, setIsImporting] = useState(false);
  const [showFileTypeError, setShowFileTypeError] = useState(false);
  const fileTypes = ['CSV'];

  const handleChange = (uploadFile) => {
    setShowFileTypeError(false);
    if (uploadFile) {
      setFileName(uploadFile.name);
      var reader = new FileReader();
      reader.readAsText(uploadFile, "UTF-8");
      
      reader.onload = async function (evt) {
        setFileContent(evt.target.result);
      }
      reader.onerror = function (evt) {
        console.log(evt);
      }
    }
  };

  const userImportError = () => {
    return (
      <>
        <p>We could not process your file due to invalid data. Please check the format of your data, revise, and try again.
          <a href={require("../bulkoperation/safecase_user_upload_template.csv")}> Download the template</a> or view the <a href={require("../bulkoperation/safecase_user_upload_example.csv")}>example</a> if you need help.
        </p>
        <p>If this error persist, please reach out to support and we'll help you resolve it.</p>
      </>
    )
  };

  const onUpload = async () => {
    if(fileContent) {
      setIsImporting(true);
      const bulkImport = await uploadBulk({variables: {csvContents: fileContent}});
      if(bulkImport.errors) {
        error({title: 'User Import', msg: userImportError()});
      } else {
        success({title: 'User Import', msg: 'Import started. See log for details.'});
      }
      togglePortal();
    }
  }

  const handleErrorDrop = e => {
    setShowFileTypeError(true);
    setFileContent('');
    setFileName('');
  };

  return (
    <div className="offcanvas-form">
      <Form>
        <Row>
          <Col md="1"><label className="circle">1</label></Col>
          <Col md="11"><h5 className='ms-3 mt-1'><b>Download the template</b></h5></Col>
        </Row>
        <Row className='mt-4'>
          <Col>
          To get started, please download and use this template to input your data
          <br></br><br></br>
          What's allowed:
          <ul>
          <li>
              Number of users must be less than 500
            </li>
            <li>
              Alphabet, numbers, hyphens, underscore, period for User ID
            </li>
            <li>
              Local or international numbers must be less than 15 characters
            </li>
          </ul>
          <p>
            <a className="download-template-hyperlink" href={require("../bulkoperation/safecase_user_upload_example.csv")}>View our example</a> or  
            <Link to={'/support'} className="download-template-hyperlink"> contact support</Link> if you need assistance. 
          </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mt-3 mb-3">
              <a
                className="btn btn-primary d-block download-template-button"
                href={require("../bulkoperation/safecase_user_upload_template.csv")}
                download="safecase_user_upload_template"
              >
                Download Template</a>
            </Form.Group>
          </Col>
        </Row>
        <Row className='mt-3'>
          <Col md="1"><label className="circle">2</label></Col>
          <Col md="11"><h5 className='ms-3 mt-1'><b>Import File</b></h5></Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <div className='mt-4'>
                Drag and drop your file into the uploader below or select and find your file. 
              </div>
              <br/>
              <FileUploader handleChange={handleChange} name="file" types={fileTypes} classes="file-uploader"
              onTypeError={e => handleErrorDrop(e)}
              >
                <div className='bulk-import-button'>
                  <div>
                    {
                      isImporting ? (
                        <div className="spinner-border" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      ):
                      (
                        ''
                      )
                    }
                    {
                      fileName == '' ? (
                        <p>
                          Select a .csv file to upload
                          <br/>
                          <small style={{textTransform: 'lowercase'}}  className="privoro-gray">
                            or drag and drop here
                          </small>
                          <br></br><br></br>
                           <Button>Select File</Button>
                        </p>
                      ) 
                      : 
                      (
                        <p className="text-wrap">
                          {fileName}
                          <br></br><br></br>
                          {
                            !isImporting ? (
                              <Button>Delete & Start Over</Button>
                            ):('')
                          }
                        </p>
                      )
                    }
                  </div>
                </div>
              </FileUploader>
            </Form.Group>
            
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mt-3 mb-3">
              <Button style={{width: '100%'}} onClick={onUpload}>
                Import File
              </Button>
            </Form.Group>
          </Col>
        </Row>
        <Row hidden={!showFileTypeError}>
          <Col><strong>File must be in .csv format. Please save the file in this format and upload again.</strong></Col>
        </Row>
      </Form>
    </div>
  )
}

export default compose(
  withAlerts
)(BulkImportComponent);
