import { gql, useMutation } from '@apollo/client';

const SIGN_AGREEMENT= gql`
  mutation($agreement: AgreementInput) 
  {
    SignAgreement(agreement: $agreement) {
      result {
        id
        agreement
      }
    }
  }
`;

export function useAgreementMutations() {

  const [signAgreement] = useMutation(SIGN_AGREEMENT);
  
  return { signAgreement };
}
