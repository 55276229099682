import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Modal, Button, Row, Col} from 'react-bootstrap';

export const DeletePolicyConfirmModal = (props) => {
  const {onChange} = props;
  const [showConfirm, setShowConfirm] = useState(false);
  let showPopupBasedOnAssignments = (props?.assignmentcount && props?.assignmentcount > 0) ? true : false;
  const handleShow = () => {
    if(showPopupBasedOnAssignments){ 
      setShowConfirm(true);
    }
    else // just skip the popup, no assignments to this policy
    {
      onChange();
      handleClose();
    }
  }
  const handleClose = () => setShowConfirm(false);

   let modalConfirmMessage = props?.message ?? "default message"
  
  const handleConfirm = () => {
    onChange();
    if(showConfirm) {
      handleClose();
    }
  };

  return (
    <>
      <span className="link-span" onClick={handleShow}>Delete Policy</span>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showConfirm}
        onHide={handleClose}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="align-items-center">
            <Col className="align-self-center">
              <b className="text-center">{modalConfirmMessage}</b>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-center">
            <Button onClick={handleClose} variant="secondary">Cancel</Button> 
            <Button onClick={handleConfirm} className="ms-2">Confirm</Button> 
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

DeletePolicyConfirmModal.propTypes = {
  onChange: PropTypes.func,
  scope: PropTypes.string,
  assignmentcount: PropTypes.number
}

export default DeletePolicyConfirmModal;
