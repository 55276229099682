import { React, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { Row, Col, Form } from 'react-bootstrap';
import { usePortalContext } from '../../lib/context/portalContext';
import { useDeviceMutations } from './deviceMutations';
import withAlerts from '../../lib/withAlerts';
import {compose} from 'redux';
import {useBulkOTACheck, useAnyOTACheck} from '../../lib/useOTACheck';
import FirmwareAssignmentConfirmModal from './firmwareAssignmentConfirmModal';
import { Link } from 'react-router-dom';
import moment from 'moment'

const BulkOtaDetails = props => {
  const [FWtype, setFWType] = useState('latest');
  const [currentTime] = useState(moment.utc().toISOString());
  const [startTime, setStartTime] = useState(currentTime);
  const { togglePortal, isShowing, rowData, origin } = usePortalContext();
  const { startBulkOTA } = useDeviceMutations();
  const {alerts} = props;
  const bulkOTARunning = useBulkOTACheck();
  const anyOTARunning = useAnyOTACheck();

  const files = {
    firmware: `/user_agreement/support/firmware_release_notes.pdf`
  };

  const handleFirmwareChange = async () => {

    const bulkOtaJob = await startBulkOTA({
        variables: {
          fw_ver: 'latest',
          start: startTime,
          timeout: moment(startTime).add(30, 'days').toISOString()
        }
    });

    if(bulkOtaJob.errors) {
      alerts.error({title: 'Firmware Update', msg: 'There was an error updating firmware. Please try again.'});
    } else {
      alerts.success({title: 'Firmware Update', msg:'Update started. See log for details.'});
    }  
  
    togglePortal();
    return null;    
  }

  return ( <>
        <div className="offcanvas-form" data-testid="offcanvas-bulk-ota">
          <Form className="mb-3" >
            <Row className="mb-3">
              <Col>
                <p><strong>This action will replace all prior, outstanding OTA updates. All online devices will be updated to the latest firmware version.</strong></p>
                <p>To update to the latest firmware for all SafeCases please:</p>
                <ul className="font-no-underline">
                  <li>
                    Review the <a href={files.firmware} target="_blank" className="font-no-underline">SafeCase Firmware Release</a> notes
                  </li>
                  <li>
                    Schedule the Start Time
                  </li>
                </ul>
                <p>Note that this process has a daily operation window of 4 hours and will automatically expire in 30 days if incomplete.</p>
                <p>Please <Link to={'/support'} className="font-no-underline"> contact support</Link> if you need further assistance.</p>
                
                <Form.Group>
                  <Form.Label>Start Time</Form.Label>
                  <Form.Select
                    onChange={(e) => setStartTime(e.currentTarget.value)}
                    disabled={bulkOTARunning || anyOTARunning}
                  >
                  {(() => {
                    const options = [];
                    options.push(<option key={0} value={moment(currentTime).toISOString()}>Now</option>);
                    for (let i = 1; i <= 24; i++) {
                      let optionValue = moment(currentTime).minutes(0).seconds(0).milliseconds(0).add(i, 'hours');
                      options.push(<option key={i} value={optionValue.toISOString()}>{optionValue.utc().format('MMM DD, YYYY HH:mm:ss')} UTC</option>);
                    }
                    return options;
                  })()}                   
                  </Form.Select>
                </Form.Group>

                <Form.Group className="my-3" hidden={!bulkOTARunning}>
                  <Form.Label className='noCapitalize'>Firmware update to all SafeCases in progress, please wait for the update to complete.</Form.Label>
                </Form.Group>
                <Form.Group className="my-3" hidden={!anyOTARunning || bulkOTARunning}>
                  <Form.Label className='noCapitalize'>Firmware update in progress, please wait for the update to complete.</Form.Label>
                </Form.Group>
              </Col>
            </Row>

            <Form.Group>
              <Row className="mb-3">
                <Col>
                  <div className="d-grid gap-2">
                    <FirmwareAssignmentConfirmModal onChange={handleFirmwareChange} scope={"all"} disabledbutton={bulkOTARunning || anyOTARunning} />
                  </div>
                </Col>
              </Row>
            </Form.Group>
          </Form>
        </div>
    </> )
}

export default compose(
  withAlerts
)(BulkOtaDetails);
