import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col, Form, Badge} from 'react-bootstrap';
import {useQuery,gql} from "@apollo/client";
import {compose} from 'redux';
import {usePortalContext} from '../../lib/context/portalContext';
import withAlerts from '../../lib/withAlerts';
import DeleteConfirmModal from '../deleteConfirmModal';
import {useHDMConfigMutations} from './hdmConfigMutations';
import {modes} from './hdmPresets';

const HDMConfigInfo = props => {
  const {rowData, togglePortal} = usePortalContext();
  const {id: hdm_config_preset_profile_id, name, hdm_config_preset_device_assignments} = rowData || {};
  const {success, error} = props.alerts;
  const {remove} = useHDMConfigMutations();
  
  const {data, loading} = useQuery(PRESET_OPTIONS, {
    variables: {
      hdm_config_preset_profile_id: hdm_config_preset_profile_id
    },
    skip: !hdm_config_preset_profile_id
  });

  let options = data?.HDMConfigOptions?.result || [];
  options = ([...options].sort((a,b) => a.sequence - b.sequence));
  
  const getDisplayOptions = (option,i) => {
    let mode = modes.find((mode) => mode.predefined_preset == option.predefined_preset);
    
    return (
      <React.Fragment key={i}>
        <Form.Label>Preset {option.sequence}: {mode?.pretty}</Form.Label>
        <Form.Group className="rules-pills badge-container hdm-config-pill">
          <Row>
            <Col className="">
              {option.is_cellular_off && <Badge pill bg="none">Cellular Off</Badge>}
              {option.is_wifi_off && <Badge pill bg="none">Wifi Off</Badge>}
              {option.is_bluetooth_off && <Badge pill bg="none">Bluetooth Off</Badge>}
            </Col>
          </Row>
        </Form.Group>
      </React.Fragment>
    )
  };

  const handleDeleteHDMProfile = async () => {

    const removeProfile = await remove({
      variables: {
        hdm_config_preset_profile_id: hdm_config_preset_profile_id
      }
    });

    if(removeProfile?.errors) {

      let userFacingError = ('HDM Profile deletion failed.');
      console.error(userFacingError + JSON.stringify(removeProfile?.errors));
      error({msg: userFacingError});
      
    } else {
      togglePortal();
      success({msg: 'HDM Profile deleted.'});
      
    }
  };

  return ( !loading && 
    <div className="offcanvas-form">
      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Profile Name</Form.Label>
            <Form.Control type="text" defaultValue={name} readOnly={true} disabled/>
          </Form.Group>

          { options?.length && options.map((opt, i) => getDisplayOptions(opt,i))}
          
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <DeleteConfirmModal
            onChange={handleDeleteHDMProfile}
            scope={"hdm_config"} 
            message={`Deleting this Profile will leave ${hdm_config_preset_device_assignments?.length} SafeCase${hdm_config_preset_device_assignments?.length === 1 ? '' : 's'} with the default profile.`}
            assignmentcount={hdm_config_preset_device_assignments?.length}
            title="HDM Profile" />
        </Col>
      </Row>
    </div>
  )
}

const PRESET_OPTIONS = gql`
  query getPresetOptions($hdm_config_preset_profile_id: String!) {
    HDMConfigOptions(hdm_config_preset_profile_id: $hdm_config_preset_profile_id) {
      result {
        option_name
        predefined_preset
        sequence
        is_cellular_off
        is_wifi_off
        is_bluetooth_off
        is_nfc_off
        is_mic_off
        is_camera_off
      }
    }
  }
`;

HDMConfigInfo.propTypes = {
  alerts: PropTypes.shape({
    error: PropTypes.func,
    success: PropTypes.func
  })
}

export default compose(
  withAlerts,
)(HDMConfigInfo);
