import React, {useRef, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {useRecoilState} from 'recoil';
import {pageSizeRecoil} from './recoil';
import {usePortalContext} from '../lib/context/portalContext';

const Table = props => {
  const { data, columns, loading, pageSize, pageNum, setPageNum, totalRecords, setSort, disableRowClick, testId } = props;
  const {togglePortal, isShowing} = usePortalContext();
  const [tablePageSize, setTablePageSize] = useRecoilState(pageSizeRecoil);
  const tableRef = useRef();
  let firstOnPage = (Number(pageNum)-1) * Number(tablePageSize) +1
  let lastOnPage = Number(pageNum) * Number(tablePageSize)
  // corner case for showing correct range on final page (because it doesn't always have a full page)
  if(Number(pageNum) * Number(tablePageSize) > totalRecords)
  {
    lastOnPage = totalRecords;
  }

  /**
   * Jump the page number back if the listing is empty
   * This bug happens after deleting single record on pages > 1
   * */
  useEffect(()=>{
    if(!loading && data && data.length === 0 && Number(pageNum) > 1) {
      setPageNum(Number(pageNum) - 1);
    }
  }, [data, pageNum, loading]);



  let handleTableChange = (type, details) => {
    // all table mutation events pass thru here
    //console.log('handletablechange', type, details);

    if(type === 'pagination') {
      if(details?.sizePerPage)
      {
        setTablePageSize(details.sizePerPage);
      }

      if(details?.page) {
        setPageNum(details.page);
      }
    }

    if(type === 'sort') {
      if(details?.sortOrder && details?.sortField) {
        setSort(details);
      }
    }
    // update and resend query based on this event
  };

  const emptyLayout = () => (
    <Row style={{height: '300px'}}>
      <Col className="my-auto">
        <h2>No results</h2>
        <p>Please try a different search term.</p>
      </Col>
    </Row>
  );

  const rowEvents = disableRowClick ? {} : {
    onClick: (e, row, rowIndex) => {
      togglePortal(row);
    }
  };

  const selectRow = {
    mode: 'radio',
    clickToSelect: !disableRowClick,
    classes: () => { return isShowing ? 'selected-row' : ''},
    hideSelectColumn: true,
    hideSelectAll: true
  };

  const customTotalRender = () => {
    return (
      <>
      <span style={ { marginRight: '15px' } }> Results Per Page. </span> 
      <span className="react-bootstrap-table-pagination-total">
    Showing { firstOnPage } - { lastOnPage } of { totalRecords } Results
  </span>
      </>
    
    )
  }
  
  return (
    <div id="privoro-table" data-testid={testId}>
      <Row>
        <Col>
          <BootstrapTable
            ref={tableRef}
            hover
            remote
            defaultSorted={[{dataField: columns[columns.length -1].dataField, order: 'desc'}]}
            bootstrap4={true}
            keyField={columns[0].dataField}
            data={data}
            columns={columns}
            bordered={false}
            classes="parent-table"
            noDataIndication={!loading && emptyLayout}     
            pagination={ paginationFactory({
              paginationTotalRenderer: customTotalRender,
              page: pageNum,
              showTotal: true,
              alwaysShowAllBtns: false,
              hidePageListOnlyOnePage: true,
              sizePerPage: tablePageSize,
              sizePerPageList: [10, 20, 50, 100],
              totalSize: totalRecords, // wants total data rows dbaas tells us are available with current q, not pages
            }) }
            onTableChange={ handleTableChange }
            rowEvents={rowEvents}
            selectRow={selectRow}
          />
        </Col>
      </Row>
      
    </div>
  );
}

Table.defaultProps = {
  data: [{id: 'no data'}],
  columns: [{dataField: 'id', text: ''}],
  setSort: (() => {}),
  disableRowClick: false,
  testId: ''
}

Table.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  loading: PropTypes.bool,
  setSort: PropTypes.func
}

export default Table;
