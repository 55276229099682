import {Row, Col, Container} from 'react-bootstrap';
import {Link, Navigate, useLocation} from 'react-router-dom';
import {useEffect, useState} from "react";
import {getClient} from './client';
import {decodeJwt} from 'jose';
import {ReactComponent as Logo} from '../../assets/portal_logo.svg';
import {isAuthenticated, setAuthStore, setSessionStore, getSessionStore, resetAuthStore} from './sessionStore';
import {useReportingMutations} from './siemReporterMutations';
import {duplicateSessionCheck} from '../duplicateSessionCheck';

export const Callback = () => {
  const [error, setError] = useState(null);
  const { state } = useLocation();
  let authenticated = isAuthenticated();
  const {log} = useReportingMutations();
  let sessionStore = getSessionStore();
  
  useEffect(() => {
    let cancel = false;
    if(authenticated) { return }
      
    (async () => {
      try {
        let client = await getClient();
        let result = await client.code.getToken(window.location.href);
        let decoded = decodeJwt(result.data.access_token);
        setAuthStore(Object.assign({}, result.data, {claims: decoded}));
        setSessionStore({
          ignore: false,
          sessionStatus: 'active',
          subscriptionsQueried: false,
          subscriptionsAck: false,
        });

        let newD = new Date(decoded?.exp * 1000);
        const iso = newD.toISOString().split('.')[0]+"Z";
        
        //Log the event
        let event = {
          name: "portalLogin",
          field: "tokenExpiryTime",
          value: `${iso}`
        };

        log({variables: {event: event}});

      } catch (err) {
        if(cancel) return;
        setError(err);
        resetAuthStore();
      }
    })();

    return () => cancel = true;
  }, [authenticated, sessionStore]);
  
  if (authenticated) {
    duplicateSessionCheck();
    return <Navigate to={state?.from || '/'}/>
  }
  
  authenticated = false;
  
  return (
    <Container fluid className="signin">
      <Row>
        <Col>
          <Row className="py-5">
            <Col className="text-center">
              <div className="login-logo mx-auto">
                <Logo />
              </div>

              {error ? (
                <div data-testid="error" className="login-linkss">
                  <p>
                    Sorry, there was an error with your login.
                  </p>
                  <p>
                    <b>Description: </b>{error.message}<br />
                  </p>
                  <p>
                    You may need to <Link to={'/signin'}>try again</Link>.
                  </p>
                </div>
              ) : (
                <div data-testid="welcome" className="form-group">
                  <p>Welcome back!</p>
                  <p>Please wait while we process your authentication.</p>
                </div>
              )}

            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}
