import { React, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Form } from 'react-bootstrap'
import SearchInput from './searchInput';
import { useRecoilState } from 'recoil';
import { showDeleted } from './recoil';
import { useLocation } from 'react-router-dom';

const ActionBar = props => {
  const { children, showHidden, searchChange, title, showSearchBar = true, showSearchInput = true } = props;
  const [showDeletedKey, setShowDeletedKey] = useRecoilState(showDeleted);
  const { pathname } = useLocation();
  // default showDeleted when component mounts
  useEffect(() => (() => setShowDeletedKey(false)), [])

  const handleChange = (e) => {
    let checked = e.target.checked;
    checked ? setShowDeletedKey(checked) : setShowDeletedKey(false);
    return;
  }

  return (
    <div id="action-bar" className="p-3 pt-5" data-testid="action-bar">
      <Row className="justify-content-between">
        <Col sm="auto">
          <h1 id="h1" data-testid="action-bar-title" className="d-inline me-3">{title}</h1>
          {showHidden &&
            <Form.Check
              type="switch"
              id="custom-switch"
              data-testid="action-bar-custom-switch"
              label="Show Deleted"
              styles={{ color: 'white' }}
              onChange={handleChange}
              inline
            />
          }
        </Col>
        {showSearchBar &&
          <Col sm="auto" className="action-buttons">
            <Row className="align-items-end">
              <Col>
                {showSearchInput && 
                  <SearchInput onChange={searchChange} />
                }
                {children}
              </Col>
            </Row>
          </Col>
        }
      </Row>
    </div>
  )
}

ActionBar.defaultProps = {
  title: ''
}

ActionBar.propTypes = {
  children: PropTypes.any,
  showHidden: PropTypes.bool,
  searchChange: PropTypes.func,
  title: PropTypes.string
}

export default ActionBar
