import {gql, useMutation} from '@apollo/client';

const IMPORT_DEVICES = gql`
  mutation ($file: Upload!) {
    deviceCsvUpload(file: $file) {
      filename
    }
  }
`;

const ENABLE_DEVICE = gql`
  mutation ($serial_number: String!) {
    EnableDevice(serial_number: $serial_number)
  }
`;

const DISABLE_DEVICE = gql`
  mutation ($serial_number: String!) {
    DisableDevice(serial_number: $serial_number)
  }
`;

const REMOVE_DEVICE = gql`
  mutation ($serial_number: String!) {
    RemoveDevice(serial_number: $serial_number)
  }
`;

const SET_CRITERIA = gql`
  mutation ($serial_number: String!, $field: DeviceField!, $value: String!) {
    AssignDeviceCriteria(serial_number: $serial_number, field: $field, value: $value)
  }
`;

const START_OTA = gql`
  mutation ($serial_number: String!, $firmware_id: String!) {
    StartDeviceOTA(serial_number: $serial_number, firmware_id: $firmware_id)
  }
`;

const START_BULK_OTA = gql`
mutation ($fw_ver: String!, $start: String!, $timeout: String!) {
  StartBulkDeviceOTA(fw_ver: $fw_ver, start: $start, timeout: $timeout)
}
`;

const STOP_OTA = gql`
  mutation ($serial_number: String!) {
    StopDeviceOTA(serial_number: $serial_number)
  }
`;



export function useDeviceMutations () {

  const [enable] = useMutation(ENABLE_DEVICE,
    { refetchQueries: ['getDevices'] },
  );

  const [disable] = useMutation(DISABLE_DEVICE,
    { refetchQueries: ['getDevices'] },
  );

  const [remove] = useMutation(REMOVE_DEVICE,
    { refetchQueries: ['getDevices'] },
  );

  const [upload] = useMutation(IMPORT_DEVICES,
    { refetchQueries: ['getDevices'] },
  );

  const [startOTA] = useMutation(START_OTA,
    { refetchQueries: ['getDevices'] },
  );

  const [stopOTA] = useMutation(STOP_OTA,
    { refetchQueries: ['getDevices'] },
  );

  const [startBulkOTA] = useMutation(START_BULK_OTA,
    { refetchQueries: ['getDevices'] },
  );

  const [setCriteria] = useMutation(SET_CRITERIA,
    { refetchQueries: ['getDevices' ,'contextGetDevices', 'getDevicesPolicyAssignments'] },
  );
  return { enable, disable, remove, upload, startOTA, stopOTA, startBulkOTA, setCriteria };
}
