import {gql, useMutation} from '@apollo/client';
import {ASSIGN_TO_GROUP, REMOVE_FROM_GROUP} from '../groups/groupMutations'

const CREATE_POLICY = gql`
  mutation ($name: String!) {
    AddPolicy(policy_name: $name)
  }
`;

const CREATE_POLICY_FORM = gql`
  mutation ($policy: PolicyInput) {
    AddPolicyForm(policy: $policy)
  }
`;

const EDIT_POLICY_FORM = gql`
  mutation ($policy: PolicyInput) {
    EditPolicyForm(policy: $policy)
  }
`;

const SET_CRITERIA = gql`
  mutation ($name: String!, $field: PolicyField!, $value: String!) {
    AssignPolicyCriteria(policy_name: $name, field_name: $field, value: $value) 
  }
`;

const ENABLE_POLICY = gql`
  mutation ($name: String!) {
    EnablePolicy(policy_name: $name)
  }
`;

const DISABLE_POLICY = gql`
  mutation ($name: String!) {
    DisablePolicy(policy_name: $name)
  }
`;

const REMOVE_POLICY = gql`
  mutation ($name: String!) {
    RemovePolicy(policy_name: $name)
  }
`;
 
export function usePolicyMutations () {

  const [create] = useMutation(CREATE_POLICY_FORM,
    { refetchQueries: ['getPolicies'] },
  );

  const [edit] = useMutation(EDIT_POLICY_FORM,
    { refetchQueries: ['getPolicies', 'getDevicesPolicyAssignments'] },
  );

  const [setCriteria] = useMutation(SET_CRITERIA,
    { refetchQueries: ['getPolicies', 'getDevicesPolicyAssignments'] },
  );

  const [enable] = useMutation(ENABLE_POLICY,
    { refetchQueries: ['getPolicies'] },
  );

  const [disable] = useMutation(DISABLE_POLICY,
    { refetchQueries: ['getPolicies'] },
  );

  const [remove] = useMutation(REMOVE_POLICY,
    { refetchQueries: ['getPolicies', 'getDevicesPolicyAssignments'] },
  );

  const [groupAssign] = useMutation(ASSIGN_TO_GROUP,
    { refetchQueries: ['getPolicies'] },
  );

  const [groupUnassign] = useMutation(REMOVE_FROM_GROUP,
    { refetchQueries: ['getPolicies'] },
  );

  return { create, edit, setCriteria, enable, disable, remove, groupAssign, groupUnassign };
}
