import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Modal, Button, Row, Col} from 'react-bootstrap';

export const FirmwareAssignmentConfirmModal = (props) => {
  const {onChange, disabledbutton} = props;
  const [showConfirm, setShowConfirm] = useState(false);
  const handleShow = () => setShowConfirm(true);
  const handleClose = () => setShowConfirm(false);

  let modalConfirmMessage = "Are you sure you want to update firmware on this SafeCase?"
  if(props?.scope === "all") {
    modalConfirmMessage = "Are you sure you want to apply this firmware update to ALL SafeCases in the system?"
  } 
  let buttonText = "Update Firmware"
  if(props?.scope === "all") {
    buttonText = "Update All Firmware"
  } 
  
  const handleConfirm = () => {
    onChange();
    if(showConfirm) {
      handleClose();
    }
  };

  return (
    <>
      <Button data-testid="update-firmware-form-button" onClick={handleShow} disabled={disabledbutton}>{buttonText}</Button>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showConfirm}
        onHide={handleClose}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Update</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="align-items-center">
            <Col className="align-self-center">
              <b className="text-center">{modalConfirmMessage}</b>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-center">
            <Button data-testid="cancel-modal" onClick={handleClose} variant="secondary">Cancel</Button> 
            <Button data-testid="confirm-modal" onClick={handleConfirm} className="ms-2">Confirm</Button> 
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

FirmwareAssignmentConfirmModal.propTypes = {
  onChange: PropTypes.func,
  scope: PropTypes.string
}

export default FirmwareAssignmentConfirmModal;
