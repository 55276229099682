import {gql, useMutation} from '@apollo/client';

const CREATE_PROFILE = gql`
  mutation ($profile: HDMConfigProfileInput) {
    CreateHDMConfigProfile(profile: $profile)
  }
`;

const ASSIGN_PROFILE = gql`
  mutation ($device_id: String!, $hdm_config_preset_profile_id: String!) {
    AssignHDMConfigProfile(device_id: $device_id, hdm_config_preset_profile_id: $hdm_config_preset_profile_id)
  }
`;

const UNASSIGN_PROFILE = gql`
  mutation ($device_id: String!, $hdm_config_preset_profile_id: String!) {
    UnassignHDMConfigProfile(device_id: $device_id, hdm_config_preset_profile_id: $hdm_config_preset_profile_id)
  }
`;

const REMOVE_PROFILE = gql`
  mutation ($hdm_config_preset_profile_id: String!) {
    RemoveHDMConfigProfile(hdm_config_preset_profile_id: $hdm_config_preset_profile_id)
  }
`;

export function useHDMConfigMutations () {

  const [create] = useMutation(CREATE_PROFILE,
    { refetchQueries: ['getHDMConfigProfiles', 'getDevices'] },
  );

  const [assign] = useMutation(ASSIGN_PROFILE,
    { refetchQueries: ['getHDMConfigProfiles', 'getDevices'] },
  );

  const [unassign] = useMutation(UNASSIGN_PROFILE,
    { refetchQueries: ['getHDMConfigProfiles', 'getDevices'] },
  );

  const [remove] = useMutation(REMOVE_PROFILE,
    { refetchQueries: ['getHDMConfigProfiles', 'getDevices'] },
  );

  return {create, assign, unassign, remove};
}
