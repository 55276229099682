import {Row, Col} from 'react-bootstrap';

export const NotFound = () => {
  return (
    <div className="h-100 d-flex align-items-center justify-content-center not-found-container">
      <Row>
        <Col>
          <h1>404 Not Found</h1>
          <p>The requested route could not be found.</p>
        </Col>
      </Row>
    </div>
  )
}
