import React, {useState} from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import variables from '../../styles/_variables.module.scss';
import { useCancelJobMutations } from './cancelJobMutations';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';

export const CancelConfirmModal = (props) => {
    const {percentage, jobid, state, cell, type} = props;
    const {cancel} = useCancelJobMutations();
    const [showConfirm, setShowConfirm] = useState(false);
    const handleClose = (e) => {
        e?.stopPropagation();
        setShowConfirm(false);
    }
    const handleShow = (e) => {
        e?.stopPropagation();
        setShowConfirm(true);
    }
    const handleConfirm = async (e) => {
        e?.stopPropagation();
        if(showConfirm) {
          handleClose();
          const cancelJobTask = await cancel({variables:{"jobId":jobid}});
        }
    };

    const jobTypes = {
      "USER_IMPORT": {
        title: "User Import",
        msg: "Cancel user import job?",
      },
      "DEVICE_FW_UPGRADE": {
        title: "Firmware Update",
        msg: "Cancel firmware update for all SafeCases?"
      }
    };

    return (
        <>
        <div>
            <Row>
            <Col sm="1" onClick={handleShow}>
                <span className="fa-layers fa-fw fa-lg me-4">
                    <FontAwesomeIcon icon={faCircle} size="2x" color={state.color} />
                    <FontAwesomeIcon transform="shrink-1 right-6" icon={state.icon} color={variables.privoroRed} />
                </span>
            </Col>
            <Col sm="9" className='ms-3'>
                { cell === 'P' ? 
                    <ProgressBar now={percentage} label={`${percentage}%`} animated/>
                    : 
                    state?.stage
                }
            </Col>
            </Row>
        </div>
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={showConfirm}
            onHide={handleClose}
            animation={false}
        >
        <Modal.Header closeButton>
            <Modal.Title>Cancel {jobTypes[type]?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row className="align-items-center">
                <Col className="align-self-center">
                <b className="text-center">{jobTypes[type]?.msg}</b>
                </Col>
            </Row>
        </Modal.Body>
        <Modal.Footer>
            <div className="d-flex justify-content-center">
                <Button onClick={handleClose} variant="secondary">Cancel</Button> 
                <Button onClick={handleConfirm} className="ms-2">Confirm</Button> 
            </div>
        </Modal.Footer>
        </Modal>
        </>
    )
}

export default CancelConfirmModal;
