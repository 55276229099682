import React, {useMemo, useState} from 'react';
import {compose} from 'redux';
import {Button, Row, Col, Form} from 'react-bootstrap';
import withAlerts from '../../lib/withAlerts';
import {usePolicyMutations} from './policyMutations';
import {usePortalContext} from '../../lib/context/portalContext';
import {useForm} from "react-hook-form";
import DeletePolicyConfirmModal from './deletePolicyConfirmModal';
import { ErrorMessage } from '@hookform/error-message';
import cx from 'classnames';
import { useLazyQuery, gql } from "@apollo/client";

const EditPolicy = props => {
  const {togglePortal, rowData} = usePortalContext();
  const [noRulesError, setNoRulesError] = useState(null);
  const [thresholdError, setThresholdError] = useState(null);
  const [existsError, setExistsError] = useState(null);
  const {success,error} = props?.alerts;
  const {create, edit, enable, disable, remove} = usePolicyMutations();
  const [getPolicy] = useLazyQuery(GET_POLICY);

  const {register, setFocus, handleSubmit, getValues, watch, formState: {errors}} = useForm({
    mode: 'onBlur',
    defaultValues: useMemo(() => {
      if( rowData?.type === 'click' ) {
        return null;
      } 
      return rowData;
    }, [rowData])
  });

  const handleDeletePolicy = async() => {
    const deletePolicy = await remove({variables:{name: rowData?.name}});

    if(deletePolicy?.errors) {

      let userFacingError = ('Policy deletion failed');
      console.error(userFacingError + JSON.stringify(deletePolicy?.errors));
      error({title: 'Delete Policy', msg: userFacingError});
      
    } else {
      success({title: 'Deleted Policy', msg: `Policy deleted.`});
      togglePortal();
    }
  }

  const onSubmit = async (formData) => {
    
    //reshape the policy object since I loaded the entire policy into formData defaultValues
    let policyShape = {
      is_phone_protected: formData.is_phone_protected,
      has_battery_warning: formData.has_battery_warning,
      is_cellular_connected: formData.is_cellular_connected,
      name: formData.name
    }
    
    //attempt to strip duplicate values from policy update to prevent excess logging
    function removeDuplicateValues(newObject, originalObject) {
      for (let property in newObject) {
        if (property === "name") continue;
        if (originalObject[property] === newObject[property]) {
            delete newObject[property];
        }
      }
    }
    
    //New Policy, don't remove any form data
    if(rowData?.id) removeDuplicateValues(policyShape, rowData);

    //Make sure at least 1 policy rule is selected
    if(noRulesSelected(formData)) {
      setNoRulesError('At least one Policy Rule must be selected.');
      return;
    }
    
    //Verify battery percentage is there if rule is true
    if(formData.has_battery_warning || policyShape.has_battery_warning) {
      
      //Make sure the existing threshold isn't the same
      if(rowData?.battery_warning_threshold !== formData.battery_warning_threshold) {
        //We set a battery rule
        policyShape.battery_warning_threshold = formData.battery_warning_threshold ?? 0;
      }

      //Make sure threshold is there for new policy
      if (rowData.battery_warning_threshold === undefined) {
        policyShape.battery_warning_threshold = formData.battery_warning_threshold ?? 0;
      }

    } else if (rowData?.has_battery_warning && !formData.has_battery_warning) {
      //We're removing a battery rule from an existing policy
      policyShape.battery_warning_threshold = 0;
    }

    //throw an error if the threshold isnt set
    if( formData.has_battery_warning) {
      if(formData.battery_warning_threshold === 0 || formData.battery_warning_threshold === undefined) {
        setThresholdError('Battery Level Sufficient percentage is required.');
        return;
      }
    }      
    
    let variables = {
      variables: {
        filters: {
          field: "name", value: `${formData?.name}`
        }
      }
    };

    //lazyQuery
    let checkExistingPolicy = await getPolicy(variables);
    if(checkExistingPolicy?.data?.Policies && checkExistingPolicy?.data?.Policies?.result?.length > 0) {
      let checkPolicies = checkExistingPolicy?.data?.Policies?.result || [];
      //prevent inline error when editing existing policy. name check is positive when editing policy so compare id
      let match = checkPolicies.find(p => p.id === rowData?.id);
      if(!match) {
        setExistsError('Policy Name already exists.');
        setFocus('name');
        return;
      }      
    }
    
    if(rowData?.id) {
      //Modifying an existing policy
      if(rowData?.name !== formData?.name) { 
        
        // name change. if originalName is defined, then gql attempts to rename before doing all subsequent form updates
        policyShape.originalName = rowData?.name;
      }

      const editPolicy = await edit({variables: {policy: policyShape}});
      
      if(editPolicy?.errors) {
        
        let userFacingError = ('There was an error editing the policy. Please try again.');
        console.error(userFacingError + JSON.stringify(editPolicy?.errors));
        error({title: 'Edit Policy', msg: userFacingError});
        
      } else {
        success({title: 'Edit Policy', msg: `Policy updated.`});
      } 
    } else {
      //New Policy to create
      const createPolicy = await create({variables: {policy: policyShape}});
      
      if(createPolicy?.errors) {
        
        let userFacingError = (`There was an error creating the new policy. Please try again.`);
        console.error(userFacingError + JSON.stringify(createPolicy?.errors));

        if(JSON.stringify(createPolicy?.errors).includes('duplicate key value violates unique constraint') ||
        JSON.stringify(createPolicy?.errors).includes('already exists') ) {
          userFacingError = 'Policy already exists'
        }
        
        error({title: 'Create Policy', msg: userFacingError});
        
      } else {
        success({title: 'Create Policy', msg: `Policy created.`});
      }
    }
    togglePortal();
  }

  const handleBatteryRule = e => {
    let val = getValues(e.target.name);
    if(val) {
      setFocus('battery_warning_threshold');
      setNoRulesError(null);
    } else  {
      setThresholdError(null);
    }
  }

  const noRulesSelected = (formData) => {
    if (
      formData.is_phone_protected === false &&
      formData.is_cellular_connected === false &&
      formData.has_battery_warning === false 
    ) {
      return true;
    }      
    return false;
  };

  return (
    <div className="offcanvas-form" data-testid="offcanvas-edit-policy">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Policy Name</Form.Label>
              <Form.Control data-testid="policy-name-text" {...register("name", {
                onChange: () => setExistsError(null),
                required: "Policy Name is required.",
                pattern: {
                  value: /^[a-zA-Z0-9-_.]+$/,
                  message: "Invalid Policy Name - Letters, numbers, hyphens, underscores, and periods are acceptable."
                },
                maxLength: {
                  value: 50,
                  message: "Length of Policy Name exceeds 50 characters."
                }})}
                type="text"
                placeholder="Policy Name"
                className={cx({'error-input': errors?.name})}
              />
            </Form.Group>
            
            <Form.Group className="mb-3">
              <Form.Label>Policy Rules</Form.Label>
                
                <div className="mb-3 ms-3">
                  <Form.Check 
                    data-testid="protected-mode-checkbox"
                    type="checkbox"
                    id="is_phone_protected"
                    label="Protected Mode Engaged"
                    {...register("is_phone_protected")}
                    className={cx({'error-input': noRulesError})}
                    onChange={() => setNoRulesError(null)}
                    
                  />
                </div>

                <div className="mb-3 ms-3">
                  <Form.Check 
                    data-testid="cell-radio-checkbox"
                    type="checkbox"
                    id="is_cellular_connected"
                    label="Cellular Radio Off"
                    {...register("is_cellular_connected")}
                    className={cx({'error-input': noRulesError})}
                    onChange={() => setNoRulesError(null)}
                    
                  />
                </div>

                <div className="mb-3 ms-3">
                  <Form.Check 
                    data-testid="battery-level-checkbox"
                    type="checkbox"
                    id="rule-battery"
                    label="Battery Level Sufficient"
                    {...register("has_battery_warning", {
                      onChange: handleBatteryRule}
                    )}
                    className={cx({'error-input': noRulesError})}
                  />
                </div>
                
                <Row className="mb-3 ms-5 align-items-end">
                  <Col sm="auto">
                    <Form.Label>Above</Form.Label>
                  </Col>
                  <Col sm="auto">
                    <Form.Select
                      data-testid="threshold-combobox"
                      disabled={!watch('has_battery_warning')}
                      className={cx({'error-input': thresholdError})}
                      {...register("battery_warning_threshold", {
                        onChange: () => setThresholdError(null),
                        valueAsNumber: true
                      })}
                    >
                      {(() => {
                        const options = [];
                        options.push(<option key='' value='0'></option>);
                        for (let i = 10; i <= 50; i+=10) {
                          options.push(<option key={i} value={i}>{i}%</option>);
                        }
                        return options;
                      })()}
                    </Form.Select>
                  </Col>
                </Row>
                
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="d-grid gap-2">
              <Button
                className="mb-3"
                type="submit">Save Policy</Button>

              <div className="mb-3">
                <ErrorMessage
                  errors={errors}
                  name="name"
                  render={({ message }) => <strong>{message}</strong>}
                />
                     
                {existsError && <strong>{existsError}<br/></strong>}
                {noRulesError && <strong>{noRulesError}<br/></strong>}
                {thresholdError && <strong>{thresholdError}<br/></strong>}
              </div>
            </div>
          </Col>
        </Row>
        {rowData?.name ? 
          <Row className="mb-3">
              <Col>
                <DeletePolicyConfirmModal onChange={handleDeletePolicy} scope={"policy"} 
                message={`Deleting this policy will leave ${rowData?.assignmentCount} User-SafeCase pair(s) without a policy.`} assignmentcount={rowData?.assignmentCount} />
              </Col>
          </Row>
          : null }
      
      </Form>      
    </div>
  )
}

const GET_POLICY = gql`
  query Policies ($filters: GenericFilters) {
    Policies (filters: $filters) {
      result {
        id
        name
      }
    }
  }
`;


export default compose(
  withAlerts,
)(EditPolicy);
