import React, {useState, useRef} from 'react';
import {Modal, Button, Row, Col} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPencil} from '@fortawesome/pro-light-svg-icons';
import Form from 'react-bootstrap/Form';
import withAlerts from '../../lib/withAlerts';
import {compose} from 'redux';
import {usePolicyMutations} from './policyMutations';

export const StatusModal = (props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  const {row:{is_active, name, is_delete}} = props;
  const {success, error} = props.alerts;
  const inputRef = useRef();
  const {enable,disable,remove} = usePolicyMutations();

  const onSubmit = async () => {
    let status = inputRef.current.value;
    
    let mutateFunc = null;
    switch (status) {
      case 'enable':
          mutateFunc = await enable({variables:{name}});
          break;
      case 'disable':
          mutateFunc = await disable({variables:{name}});
          break;
      case 'remove':
          mutateFunc = await remove({variables:{name}});
          break;
      default:
          return mutateFunc;
    }

    if(mutateFunc.data) {
      success(`Successfully ${status}d policy.`)
      handleClose();
    } else {
      error('Error setting policy activation status.');
      handleClose();
    }
  }
  
  return (
    <>
      <span>
        <FontAwesomeIcon className={is_delete ? 'me-2' : 'me-2 pointer privoro-blue'} style={is_delete ? {'visibility': 'hidden'} : {}} size="lg" icon={faPencil} onClick={handleShow}/> {is_active === true ? 'Active' : 'Disabled'}
      </span>
      <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Activation Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Select ref={inputRef}>
                <option>Select activation status</option>
                <option value="enable">Enable</option>
                <option value="disable">Disable</option>
                <option value="remove">Delete</option>
              </Form.Select>    
            </Col>
          </Row>          
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-end">
            <Button onClick={handleClose} variant="secondary">Cancel</Button> 
            <Button onClick={onSubmit} className="ms-2">Submit</Button> 
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default compose(
  withAlerts
)(StatusModal);
