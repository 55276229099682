import React from 'react';
import {toast} from 'react-toastify';
import {Row, Col, Toast} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircle} from '@fortawesome/pro-solid-svg-icons';
import {faXmark, faCheck} from '@fortawesome/pro-light-svg-icons';


const CustomToast = (props) => {
  const {closeToast, toastProps:{type}, msg, title } = props;
  const icons = {
    success: {
      icon: faCheck,
      color: '#5cb85c',
      title: 'Success'
    },
    error: {
      icon: faXmark,
      color: '#d9534f',
      title: 'Error',
      component: null
    },
    default: {
      icon: faCheck,
      color: '#00778B',
      title: 'Info'
    }    
  };
  
  let iconCfg = () => {
    return (
      <>
        <FontAwesomeIcon icon={faCircle} size="lg" color={icons[type].color} />
        <FontAwesomeIcon transform="shrink-3" icon={icons[type].icon} color="white"/>
      </>
    );
  };

  return (
    <Toast className="custom-rb-toast">
      <Toast.Body>
        <Row>
          <Col style={{display: 'flex', justifyContent:'left'}}>
            <div className="fa-layers fa-fw fa-lg" style={{padding: '10px'}}>
              {iconCfg()}
            </div>
            <div style={{paddingLeft: '10px', paddingRight: '10px'}}>{msg} {icons[type].component}</div>
            <FontAwesomeIcon style={{marginLeft: 'auto'}} className="fa-lg"  icon={faXmark}/>
          </Col>
        </Row>
      </Toast.Body>
    </Toast>
)};

class Alerts {
  success(props){
    toast.success(<CustomToast {...props} />,{
      position: "bottom-left",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: 'custom-toaster',
      icon: false,
      closeButton: false,
      toastId: props.id ?? null
    });
  }

  error(props){
    toast.error(<CustomToast {...props} />,{
      position: "bottom-left",
      autoClose: false,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: 'custom-toaster',
      closeButton: false,
      icon: false,
      toastId: props.id ?? null
    });
  }

  default(props) {
    toast(<CustomToast {...props} />,{
      position: "bottom-left",
      autoClose: true,
      hideProgressBar: true,
      closeOnClick: true,
      draggable: true,
      className: 'custom-toaster',
      closeButton: false,
      icon: false,
      toastId: props.id ?? null
    }) 
  }
}

let alerts = new Alerts();

const withAlerts = (Component) => {
  return (props) => {
    return (
      <Component {...props} alerts={alerts} />
    );
  };
};

export default withAlerts;

