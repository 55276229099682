import {gql} from '@apollo/client';
import {endKeycloakSession} from '../components/auth/signOut';
import {hasAuthSession, setSessionStore, getAuthStore} from '../components/auth/sessionStore';
import {getEnv} from "../getEnv";

export async function duplicateSessionCheck() {
  let env = getEnv();
  const {access_token} = getAuthStore();
  const auth_session_state = hasAuthSession();
  const SYS_TYPE = process.env.SYS_TYPE || process.env.REACT_APP_SYS_TYPE;
  const preventLogout = ['cc-ate4','cc-ate3','cc-ate2','cc-ate1','cc-fetest','test', 'development'].indexOf(SYS_TYPE) !== -1;

  await fetch(env.graphql_endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'authorization': `Bearer ${ access_token }`
    },
    body: JSON.stringify({ query: `
      query {
        UserSessions {
          id
        }
      }` 
    }),
  })
  .then(res => res.json())
  .then(async (sessionData) => {
    if (sessionData?.data?.UserSessions?.length > 1 && auth_session_state) {
      setSessionStore({sessionStatus: "duplicate"});
      !preventLogout && await endKeycloakSession();
      return true;
    }
  })
  
  return false;

}

const USER_SESSIONS = gql`
query userSessions {
  UserSessions {
    id
  }
}
`
