import {atom} from 'recoil';
import {recoilPersist} from 'recoil-persist'
const { persistAtom } = recoilPersist();

export const showingModals = atom({
  key: 'showingModals',
  default: []
});

export const showDeleted = atom({
  key: 'showDeleted',
  default: false
});


export const isCollapsed = atom({
  key: 'isCollapsed',
  default: false
});

export const pageSizeRecoil = atom({
  key: 'pageSizeRecoil',
  default: 10,
  effects: [persistAtom]
});
