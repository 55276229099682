import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';
import {Modal, Button, Row, Col, Tooltip, OverlayTrigger} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBatteryEmpty} from '@fortawesome/pro-light-svg-icons';
import {faTriangleExclamation} from '@fortawesome/pro-solid-svg-icons';
import Form from 'react-bootstrap/Form';
import {usePolicyMutations} from './policyMutations';
import withAlerts from '../../lib/withAlerts';
import {compose} from 'redux';
import cx from 'classnames';


export const BatteryCriteriaModal = (props) => {
  const {name, battery_warning_threshold} = props.row;
  const [show, setShow] = useState(false);  
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const {success, error} = props.alerts;
  const inputRef = useRef();
  const {setCriteria} = usePolicyMutations();

  const onSubmit = async () => {
    let newVal = inputRef.current.value;
    if(newVal === battery_warning_threshold) return;

    let setPolicyCriteria = {};
    newVal = newVal === 'disable' ? "0" : newVal;
    try {
      setPolicyCriteria = await setCriteria({variables: 
        {
          name: name,
          field: 'battery_warning_threshold',
          value: newVal
        }
      });
    } catch(error) {
      setPolicyCriteria.errors = error;
    }
    
    if(setPolicyCriteria.errors) {
      error('Error setting policy criteria.');
      handleClose();
    } else {
      success('Policy criteria updated.');
      handleClose();      
    }
    
  }

  return (
    <>
      <Col onClick={handleShow} className={ cx('pointer', {'privoro-blue': battery_warning_threshold > 0}, {'default-rule': battery_warning_threshold === 0})}>
        <OverlayTrigger
          delay={800}
          key="batteryTooltip"
          placement={'top'}
          overlay={
            <Tooltip id="tooltip-battery">
              Battery Level
            </Tooltip>
          }
        >
        <span className="fa-stack pointer">
          <FontAwesomeIcon icon={faBatteryEmpty} size="2x" />
          <FontAwesomeIcon size="lg" className="fa-stack-1x fa-solid battery-triangle" icon={faTriangleExclamation}/>
        </span>
        </OverlayTrigger>
        <b className="rule-sub battery-sub">{battery_warning_threshold > 0 ? `< ${battery_warning_threshold}%` : ''} </b>
      </Col>
      <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Battery Level Threshold</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Row>
          <Col>
            <Form.Select defaultValue={battery_warning_threshold} ref={inputRef}>
              <option value="0">Select warning level</option>
              {battery_warning_threshold > 0 && <option value="disable">Disable</option>}
              
              {(() => {
                const options = [];

                for (let i = 10; i <= 90; i+=5) {
                  options.push(<option key={i} value={i}>{i}</option>);
                }
                return options;
              })()}
            </Form.Select>
          </Col>
        </Row>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-end">
            <Button onClick={handleClose} variant="secondary">Cancel</Button> 
            <Button onClick={onSubmit} className="ms-2">Submit</Button> 
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

BatteryCriteriaModal.defaultProps = {
  battery_warning_threshold: 0
}

BatteryCriteriaModal.propTypes = {
  row: PropTypes.object,
  battery_warning_threshold: PropTypes.number
};

export default compose(
  withAlerts
)(BatteryCriteriaModal);
