import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Row,Col,Form} from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';
import {useForm} from 'react-hook-form';

export const FilterBar = (props) => {

  const {register, watch, getValues, setValue} = useForm();
  let showRules = watch('filter_one') === 'policy_violations';
  
  const handleChange = (e) => {
    let values = getValues();
    
    if( values.filter_one !== 'policy_violations' ) {
      delete values.filter_two;
    }
    
    props.onChange(values);
  }  

  return(
    <div className="p-3 metric-filter-bar">
      <Row className="justify-content-between align-items-center">
        <Col>
          <Row className="justify-content-start">
            <Col md='4'>
              <InputGroup>
                <InputGroup.Text data-testid="filter-label">Filter By:</InputGroup.Text>
                <Form.Select data-testid="filter-dropdown"
                  {...register('filter_one', {onChange: handleChange})}>
                  <option key={0} value="all">All</option>
                  <option key={1} value={'policy_violations'}>Policy Violations</option>
                  <option key={2} value={JSON.stringify({'field': 'is_check_in', 'value': 'true'})}>Checked-in</option>
                  <option key={3} value={JSON.stringify({'field': 'is_check_in', 'value': 'false'})}>Checked-out</option>
                  <option key={4} value={JSON.stringify({'field': 'is_offline', 'value': 'false'})}>Online</option>
                  <option key={5} value={JSON.stringify({'field': 'is_offline', 'value': 'true'})}>Offline</option>
                </Form.Select>
              </InputGroup>    
            </Col>
            
            <Col hidden={!showRules}>
              <InputGroup>
                <InputGroup.Text>Policy Rule:</InputGroup.Text>
                <Form.Select data-testid="filter-two"
                  {...register('filter_two', {onChange: handleChange})}>
                  <option key={0} value={JSON.stringify([{'field': 'is_antisurveillance_violation', 'value': 'true'},{'field': 'is_low_batt_violation', 'value': 'true'},{'field': 'is_cellular_violation', 'value': 'true'}])}>All Violation Types</option>
                  <option key={1} value={JSON.stringify([{'field': 'is_antisurveillance_violation', 'value': 'true'}])}>Protected Mode Disengaged</option>
                  <option key={2} value={JSON.stringify([{'field': 'is_low_batt_violation', 'value': 'true'}])}>Battery Level Insufficient</option>
                  <option key={3} value={JSON.stringify([{'field': 'is_cellular_violation', 'value': 'true'}])}>Cellular Radio On</option>
                </Form.Select>
              </InputGroup>    
            </Col>
          </Row>          
        </Col>
        <Col>
          {/* <p className="text-end mb-0" style={{textTransform: 'capitalize'}}><strong>Showing: </strong>{filterState} SafeCases</p> */}
        </Col>
      </Row>
    </div>
  );
};

export default FilterBar;
