import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import RBTooltip from 'react-bootstrap/Tooltip';
import PropTypes from 'prop-types';

const Tooltip = props => {
  const {children, content} = props;

  const renderTooltip = (props) => (
    <RBTooltip
      {...props}
      className="custom-tooltip"
    >
      {content}
    </RBTooltip>
  );

  return (
    <OverlayTrigger
      overlay={renderTooltip}
      trigger={['hover', 'focus']}
      placement="bottom"
      offset={[80,15]}

    >
      <div className="d-inline-block">{children}</div>
    </OverlayTrigger>
  );
}

Tooltip.defaultProps = {
  content: ''
}

export default Tooltip;
