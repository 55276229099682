export const presetOptionsPretty = [
  {is_cellular_off: "Cellular Off"},
  {is_wifi_off: "WiFi Off"},
  {is_bluetooth_off: "Bluetooth Off"},
];

export const modes = [
  {
    predefined_preset: 0,
    option_name: "init",
    pretty: "Select Mode",
    options: [
      {is_cellular_off: null},
      {is_wifi_off: null},
      {is_bluetooth_off: null},  
    ]
  },
  {
    predefined_preset: 1,
    option_name: "radio_silent",
    pretty: "Radio Silent Mode",
    options: [
      {is_cellular_off: true},
      {is_wifi_off: true},
      {is_bluetooth_off: true},  
    ]
  },
  {
    predefined_preset: 2,
    option_name: "cell_silent", 
    pretty: "Cellular Silent Mode",
    options: [
      {is_cellular_off: true},
      {is_wifi_off: false},
      {is_bluetooth_off: false},  
    ]
  },
  {
    predefined_preset: 6,
    option_name: "wifi_only",
    pretty: "Wifi Only Mode",
    options: [
      {is_cellular_off: true},
      {is_wifi_off: false},
      {is_bluetooth_off: true},  
    ]
  },
  {
    predefined_preset: 7,
    option_name: "ble_only",
    pretty: "Bluetooth Only Mode",
    options: [
      {is_cellular_off: true},
      {is_wifi_off: true},
      {is_bluetooth_off: false},  
    ]
  }
];

//   {
//     predefined_preset: 3,
//     option_name: "wifi_silent",
//     pretty: "WiFi Silent Mode",
//     options: [
//       {is_cellular_off: false},
//       {is_wifi_off: true},
//       {is_bluetooth_off: false},  
//     ]
//   },
//   {
//     predefined_preset: 4,
//     option_name: "ble_silent",
//     pretty: "Bluetooth Silent Mode",
//     options: [
//       {is_cellular_off: false},
//       {is_wifi_off: false},
//       {is_bluetooth_off: true},  
//     ]
//   },
//   {
//     predefined_preset: 5,
//     option_name: "cell_only",
//     pretty: "Cellular Only Mode",
//     options: [
//       {is_cellular_off: false},
//       {is_wifi_off: true},
//       {is_bluetooth_off: true},  
//     ]
//   },
