import {useState, useEffect} from 'react';
import {useQuery,gql} from "@apollo/client";
import Table from '../table';
import ActionBar from '../actionBar';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import {statusFormatter, rulesFormatter, policyInfoFormatter , groupFormatter} from './columnFormatters';
import {dateFormatter} from '../dateFormatter';
import { useRecoilValue } from 'recoil';
import { pageSizeRecoil, showDeleted } from '../recoil';
import {usePortalContext} from '../../lib/context/portalContext';
import Portal from '../portal';
import EditPolicy from './editPolicy';
import {Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark} from '@fortawesome/pro-light-svg-icons';
import { faCircle} from '@fortawesome/pro-solid-svg-icons';
import variables from '../../styles/_variables.module.scss';
import {useSearch, useSort} from '../../lib/useQueryModifiers';

export const Policies = props => {
  const [search, setSearch] = useSearch();
  const [sort, setSort] = useSort();
  const [pageNum, setPageNum] = useState(1);
  const [totalRecordsState, setTotalRecordsState] = useState(0);
  const {togglePortal} = usePortalContext();
  const showingDeleted = useRecoilValue(showDeleted);
  const pageSize = useRecoilValue(pageSizeRecoil);

  const {data, loading} = useQuery(POLICIES, 
    {
      variables: {
        filters: {
          combineAnd: [
            ...search,
            ...sort,
            {
              field: "with.devices",
              value: `1`,
            },
            {
              field: "search.fields",
              value: `name`,
            },
            {
              field: "options.page.num",
              value: `${pageNum}`
            },
            {
              field: "options.page.len",
              value: `${pageSize}`
            }
          ]
        }
      },
      skip: !sort.length
    });

  let totalQueried = data?.Policies?.metadata?.page?.records ?? totalRecordsState;
  
  useEffect(()=> {
    setTotalRecordsState(totalQueried);
  }, [totalQueried]);

  useEffect(() => {
    //attempt callback to set pageNum back to 1
    //races useQuery
    return setPageNum(1)
  }, [search]);

  let policies = data?.Policies?.result ?? [];
  policies = policies.map(policy => {return {...policy, is_delete: showingDeleted, assignmentCount: policy.devices.length};});
  
  const columns = [
    {
      dataField: 'id',
      hidden: true
    },
    {
      dataField: 'name',
      text: 'POLICY NAME'
    },
    {
      dataField: 'group',
      text: 'Assignments',
      align: 'left',
      formatter: (cell, row) => {
        return (
          <>
            {row.assignmentCount ?
              <Link style={{display: 'inline', padding: '1rem', margin: '-1rem'}} to={'/policy/assignments'} state={{policyId: row.id}} onClick={e => e.stopPropagation()}>
                {row.assignmentCount}
              </Link>
            : "0"}
          </>
        );
      }
    },
    {
      dataField: 'is_phone_protected',
      text: 'PROTECTED MODE',
      align: 'left',
      formatter: (cell, row) => {
        return (
          <div className="fa-lg me-4">
            <span className="fa-layers fa-fw">
              <FontAwesomeIcon icon={faCircle} size="2x" color={cell ? variables.privoroBlue : variables.privoroOffGray} />
              <FontAwesomeIcon transform="shrink-1 right-6" icon={cell ? faCheck : faXmark} color="white"/>
            </span>
          </div>
        );
      }
    },
    {
      dataField: 'has_battery_warning',
      text: 'BATTERY LEVEL',
      align: 'left',
      formatter: (cell, row) => {
        
        return (
          <>
          {cell ?
            <Badge pill style={{padding: '0.6rem 1rem', fontWeight: 'normal'}} variant={cell ? 'primary' : 'secondary'}>
              <FontAwesomeIcon size="lg" className="me-2" icon={cell ? faCheck : faXmark} color="white"/>
              {row.battery_warning_threshold}%
            </Badge>
          :
            <div className="fa-lg me-4">
              <span className="fa-layers fa-fw">
                <FontAwesomeIcon icon={faCircle} size="2x" color={variables.privoroOffGray} />
                <FontAwesomeIcon transform="shrink-1 right-6" icon={faXmark} color="white"/>
              </span>
            </div>
          }
          </>
        );
      }
    },
    {
      dataField: 'is_cellular_connected',
      text: 'CELLULAR',
      align: 'left',
      formatter: (cell, row) => {
        return (
          <div className="fa-lg me-4">
            <span className="fa-layers fa-fw">
              <FontAwesomeIcon icon={faCircle} size="2x" color={cell ? variables.privoroBlue : variables.privoroOffGray} />
              <FontAwesomeIcon transform="shrink-1 right-6" icon={cell ? faCheck : faXmark} color="white"/>
            </span>
          </div>
        );
      }
    },
    {
      dataField: 'modified_at',
      text: 'LAST UPDATED (UTC)',
      align: 'left',
      formatter: dateFormatter,
      sort: true
    }
  ];

  return (
    <>
      <Portal title="Policy">
        <EditPolicy title="Create/Edit Policy" />
      </Portal>
      <ActionBar showHidden={false} searchChange={setSearch} title="Manage Policies">
        <div><Button onClick={togglePortal}>Create Policy</Button></div>
      </ActionBar>
      <Table
        testId="policy-table"
        columns={columns}
        data={policies}
        loading={loading}
        pageSize={pageSize}
        pageNum={pageNum}
        setPageNum={setPageNum}
        totalRecords={totalRecordsState}
        setSort={setSort}
      />
    </>
  );
};

const POLICIES = gql`
  query getPolicies($filters: GenericFilters!) {
    Policies (filters: $filters) {
      result {
      id
      name
      geofence_group_id
      description
      is_active
      is_delete
      is_phone_protected
      is_offline
      has_battery_warning
      battery_warning_threshold
      is_checked_in
      is_camera_exposed
      is_cellular_connected
      is_microphone_exposed
      is_phone_present
      policy_action_id
      created_at
      modified_at
      devices {
        id
      }
    }
    metadata {
        page {
          num
          len
          total
          inPage
          records
        }
      }
    }
  }
`;

export default Policies;
