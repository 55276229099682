import { gql, useMutation } from '@apollo/client';

const CANCEL_JOB = gql`
  mutation($jobId: String!) 
  {
    JobCancel(jobId: $jobId)
  }
`;

export function useCancelJobMutations() {

  const [cancel] = useMutation(CANCEL_JOB,
    { refetchQueries: ['getJobs'] },
  );

  return { cancel };
}
