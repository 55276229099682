import {useState, useEffect} from 'react';
import {useRecoilValue} from 'recoil';
import {pageSizeRecoil} from '../recoil';
import {useQuery,gql} from "@apollo/client";
import ActionBar from '../actionBar';
import HDMConfigModal from './hdmConfigModal';
import HDMConfigInfo from './hdmConfigInfo';
import {useSort} from '../../lib/useQueryModifiers';
import Table from '../table';
import {dateFormatter} from '../dateFormatter';
import Portal from '../portal';
import {Link} from 'react-router-dom';

export const HDMConfig = () => {
  const [sort, setSort] = useSort();
  const [pageNum, setPageNum] = useState(1);
  const [totalRecordsState, setTotalRecordsState] = useState(0);
  const pageSize = useRecoilValue(pageSizeRecoil); 

  const assignmentsFormatter = (cell, row) => {
    return (
      <>
        {row?.hdm_config_preset_device_assignments?.length ?
          <Link style={{display: 'inline', padding: '1rem', margin: '-1rem'}} to={'/hdm/assignments'} state={{hdmId: row.id, title: row.name}} onClick={e => e.stopPropagation()}>
            {row.hdm_config_preset_device_assignments.length}
          </Link>
        : "0"}
      </>
    );
  }

  const {data, loading} = useQuery(PROFILES, 
  {
    variables: {
      filters: {
        combineAnd: [
          ...sort,
          {
            field: "search.fields",
            value: `name`,
          },
          {
            field: "with.hdm_config_preset_device_assignments",
            value: "1",
          },
          {
            field: "options.page.num",
            value: `${pageNum}`
          },
          {
            field: "options.page.len",
            value: `${pageSize}`
          }
        ]
      }
    },
    skip: !sort.length
  });  

  let totalQueried = data?.HDMConfigProfiles?.metadata?.page?.records ?? totalRecordsState;

  useEffect(() => {
    setTotalRecordsState(totalQueried);
  }, [totalQueried]);

  let profiles = data?.HDMConfigProfiles?.result?.reduce((acc, sub) => {
    let prof = {...sub};
    let devices = [...prof.hdm_config_preset_device_assignments];

    if(devices.length) {

      let filtered = devices?.filter((device) => device?.status === "A");
      delete prof.hdm_config_preset_device_assignments;
      prof.hdm_config_preset_device_assignments = filtered;

    }
    acc.push(prof);

    return acc;
  }, []) || [];

  const columns = [
    {
      dataField: 'id',
      hidden: true
    },
    {
      dataField: 'name',
      text: 'HDM Profile Name'
    },
    {
      dataField: 'assignments',
      text: 'Assignments',
      align: 'left',
      formatter: assignmentsFormatter
    },
    {
      dataField: 'created_at',
      text: 'Created (UTC)',
      sort: true,
      formatter: dateFormatter
    },
    {
      dataField: 'modified_at',
      text: 'Last Updated (UTC)',
      sort: true,
      formatter: dateFormatter
    }
  ];

  return(
    <>
      <Portal title="HDM Profile">
        <HDMConfigInfo title="Profile Information" type="hdm" />
      </Portal>
      <ActionBar showHidden={false} showSearchBar={true} showSearchInput={false} title="Manage HDM Profiles">
        <HDMConfigModal />
      </ActionBar>
      <Table
        testid="hdm-config-table"
        data={profiles}
        columns={columns}
        loading={loading}
        pageSize={pageSize}
        pageNum={pageNum}
        setPageNum={setPageNum}
        totalRecords={totalRecordsState}
        setSort={setSort}
      />
    </>
  );
};

const PROFILES = gql`
query getHDMConfigProfiles($filters: GenericFilters!) {
  HDMConfigProfiles (filters: $filters) {
    result {
      id
      name
      is_deleted
      created_at
      modified_at
      hdm_config_preset_device_assignments {
        id
        status
      }
    }
    metadata {
      page {
        num
        len
        total
        inPage
        records
      }
    }
  }
}
`;

export default HDMConfig;
